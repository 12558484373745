import { Link } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
initializeIcons(undefined, { disableWarnings: true });
import { useRef, useState, useEffect, useCallback } from "react";
import {
    Checkbox,
    Panel,
    DefaultButton,
    TextField,
    SpinButton,
    Dropdown,
    IDropdownOption,
    Text,
    mergeStyleSets,
    IconButton,
    Popup,
    Layer,
    Overlay,
    FocusTrapZone,
    Toggle
} from "@fluentui/react";
import { Settings32Regular, PersonInfoFilled, ChevronDownRegular, DocumentAddRegular } from "@fluentui/react-icons";
import readNDJSONStream from "ndjson-readablestream";
import { v4 as uuidv4 } from "uuid";
import styles from "./Chat.module.css";
import {
    chatApi,
    ChatModel,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    saveChatApi,
    loadChatApi,
    loadSessionApi,
    Feedback,
    SaveResponse,
    feedbackApi,
    DataPoints,
    DeveloperOptions,
    SessionInfo,
    DataOptions,
    saveUserDetails,
    loadSharedChatApi,
    copySharedChatApi,
    YearData
} from "../../api";
import i18next from "../../i18n/i18n";
import { Trans } from "react-i18next";
import { Answer, AnswerError, AnswerLoading, AnswersHistory } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { ClearChatButton } from "../../components/ClearChatButton";
import { RegenerateButton } from "../../components/RegenerateButton";
import { appInfo, userInfo, jwtToken, supportEnvBoard } from "../../authConfig";
import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { jwtDecode } from "jwt-decode";
import DisclaimerIcon from "../../assets/Disclaimer.svg";
import Joyride, { STATUS, Step } from "react-joyride";
import { useSetState } from "react-use";
import { Box, Snackbar, SnackbarCloseReason } from "@mui/material";
import { Button } from "react-bootstrap";
import DynamicTemplatePrompt from "./DynamicTemplatePrompt";
import AIWorks_Guides from "../../assets/AIWORKS_Guidance.pdf";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import { setDefaultTemplateIdInStore, setSystemDefaultContentInStore, setUserDefaultContentInStore } from "../../Store/TemplateContents";
const useStyles = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...shorthands.padding("10px", "20px"),
        rowGap: "10px",
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "20px",
        paddingRight: "20px"
    },
    tabStyle: {
        color: "white",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        lineHeight: 2,
        fontSize: "15px",
        cursor: "pointer",
        ":active": {
            fontWeight: "bold"
        },
        ":selected": {
            fontWeight: "bold"
        },
        ":hover": {
            fontWeight: "bold"
        },
        ":target": {
            fontWeight: "bold"
        }
    },
    divOnHover: {
        ":hover": {
            backgroundColor: "#EDEBE9"
        }
    }
});
type TokenValues = {
    isInchargeOfAiWorks: string;
    userId: string;
};
let primaryIDMap: Map<number, string> = new Map();
interface Props {
    executeClear: boolean;
    callback: () => void;
    startTourByIcon: boolean;
    startTour: (start: boolean) => void;
    isSidebarOpen: boolean;
}

type Product = {
    key: string;
    text: string;
    isSelectable?: string;
    data?: Product[];
};

type SubProduct = {
    key: string;
    text: string;
};

const Chat = (props: Props) => {
    const tokenValues = jwtDecode(jwtToken || "") as TokenValues;
    const isAdmin = tokenValues["isInchargeOfAiWorks"] === "True";
    const userName = tokenValues["userId"] ?? "";
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(5);
    const [chatModel, setChatModel] = useState<ChatModel>(ChatModel.GPT_4O_MINI);
    const [onlyCitations, setOnlyCitations] = useState<boolean>(false);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [productFilter, setProductFilter] = useState<string | undefined>("0");
    const [docTypeFilter, setDocTypeFilter] = useState<string | undefined>("External");
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);

    const [selectedTemplate, setSelectedTemplate] = useState(Number);
    const [selectedTemplateContent, setSelectedTemplateContent] = useState(String);
    const [hideDynamicTemplatePopup, setHideDynamicTemplatePopup] = useState(false);
    const [templateKeyWords, setTemplateKeywords] = useState(String);

    const [conversationDetails, setConversationDetails] = useState<
        { userName: string; conversationId: string; groupId: string; title: string; conversationDate: string }[]
    >([]);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAnswerLoading, setIsAnswerLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [selectedQuestion, setSelectedQuestion] = useState<number>(0);
    const lastActiveIndexRef = useRef<number>(0);
    const lastSessionRef = useRef<SessionInfo>({});
    const lastActiveSessionRef = useRef<[SessionInfo] | []>([]);
    const lastAnswerIndexRef = useRef<number>(-1);
    const lastAnswerRef = useRef<string>("");
    const lastKeyWordRef = useRef<string | null>("");
    const lastDataPointsRef = useRef<[DataPoints] | []>([]);
    const lastThoughtsRef = useRef<string | null>("");
    const lastFollowUpRef = useRef<string[] | null>([]);
    const [todaySessionsRef, setTodaySessionsRef] = useState<[SessionInfo]>([{}]);
    const [last7SessionsRef, setLast7SessionsRef] = useState<[SessionInfo] | []>([{}]);
    const [last30SessionsRef, setLast30SessionsRef] = useState<[SessionInfo] | []>([{}]);
    const [monthlySessions, setMonthlySessions] = useState<YearData[]>([]);
    const [currentView, setCurrentView] = useState("chat");
    const [selectedSessionId, setSelectedSessionId] = useState("");
    const [currentIndex, setCurrentIndex] = useState<string>("");
    const [newChatQuestion, setnewChatQuestion] = useState<string>("");
    const [isOnSave, setIsOnSave] = useState<boolean>(false);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [subProducts, setSubProducts] = useState<SubProduct[]>([]);
    const [categories, setCategories] = useState<{ key: string; text: string }[]>([]);
    const [selectedCitation, setSelectedCitation] = useState(0);
    const [doctype, setDocType] = useState<boolean>(false);
    const [warningShown, setWarningShown] = useState<boolean>(true);
    const [isChatShared, setIsChatShared] = useState<boolean>(false);
    const [sessionId, setSessionId] = useState<string>("");
    const aimodelsAdmin = appInfo.aimodel_admin;
    const aimodelsUser = appInfo.aimodel_user;
    const retrievalModes = appInfo.retrieval_mode;
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [selectedApp, setSelectedApp] = useState(userInfo?.apptype ? (userInfo?.apptype === "Manuals" ? "Manuals" : "FAQ") : "FAQ");
    const selectedLocale = sessionStorage.getItem("selectedLocale") ?? "ja";
    const selectedLanguage = new Intl.DisplayNames(["en"], { type: "language" }).of(selectedLocale);
    const [subProductFilter, setSubProductFilter] = useState<string | undefined>("0");
    const [IsOnEdit, setIsOnEdit] = useState<boolean>(false);
    const [lastHoveredRef, setLastHoveredRef] = useState<any>(undefined);
    const [faqProductFilter, setFaqProductFilter] = useState<string | undefined>("0");
    const [applyRestriction, setApplyRestriction] = useState<boolean>(false);
    const [showSupportLink, setShowSupportLink] = useState<boolean>(false);
    const [scrollToAnswer, setScrollToAnswer] = useState<boolean>(false);
    const [nextDateToLoad, setNextDateToLoad] = useState<string>("");
    const [isSnackOpen, setIsSnackOpen] = useState(false);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [clearChatTriggered, setClearChatTriggered] = useState<boolean>(false);
    const [chatContentCleared, setChatContentCleared] = useState<boolean>(false);

    const dispatch = useDispatch();

    const onEditClick = (checked: boolean, index: number) => {
        setIsOnEdit(checked);
        setSelectedQuestion(index);
        // setSelectedAnswer(index !== 0 ? index - 1 : index);
    };

    interface State {
        run: boolean;
        steps: Step[];
    }

    useEffect(() => {
        if (props.startTourByIcon) {
            setTourOne(prevState => ({
                ...prevState,
                run: true
            }));
        }
    }, [props.startTourByIcon]);

    const [tourOne, setTourOne] = useSetState<State>({
        run: userInfo.chatTourDone === "false" ? true : false,
        steps: [
            {
                content: i18next.t(`appTour.chatTour.appType`),
                target: "#AppToggle",
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                }
            },
            {
                content: i18next.t(`appTour.chatTour.productFilter`),
                target: "#ProductManualsFilter"
            },
            {
                content: i18next.t(`appTour.chatTour.guidelines`),
                target: "#UserGuidlines"
            },
            {
                content: i18next.t(`appTour.chatTour.newChat`),
                target: "#ClearChat",
                locale: {
                    last: i18next.t(`appTour.next`)
                }
            }
        ]
    });

    const endTourOne = useCallback(() => {
        setTourOne(prevState => ({
            ...prevState,
            run: false
        }));
        setTourTemplate(prevState => ({
            ...prevState,
            run: true
        }));
        if (userInfo.answerTourDone !== "true") {
            saveUserSettings("", "", "", "", "", "", "", "", "true", "false", "", "", "false", "");
        }
        props.startTour(false);
        userInfo.chatTourDone = "true";
    }, []);

    const Spinner = () => {
        return (
            <div className="full-page-spinner">
                <div className="spinner"></div>
            </div>
        );
    };

    // Default AI models
    const defaultManualsAiModel = appInfo.aimodel_default.manuals;
    const defaultFaqAiModel = appInfo.aimodel_default.faq;
    const [emptyProduct, setEmptyProduct] = useState<boolean>(false);
    const isWapUser = userInfo.isWap === "True" || userInfo.isWap === "true";
    const handleToggleChange = (event: any, checked: any) => {
        const newApp = checked ? "FAQ" : "Manuals";
        setAppTitle(newApp);
        setSelectedApp(newApp);
        loadProductFilter(newApp, true);
        saveUserSettings("", "", "", "", "", newApp, "", "", "", "", "", "", "", "");
        userInfo.apptype = newApp;
        if (userInfo?.aimodel === "") {
            if (newApp === "Manuals") {
                setChatModel(defaultManualsAiModel);
            } else {
                setChatModel(defaultFaqAiModel);
            }
        }
    };
    const retrievalOptions = Object.keys(retrievalModes).map(key => ({
        key: key.toLowerCase(),
        text: retrievalModes[key],
        selected: retrievalMode === key,
        data: key
    }));
    const aiModel_admin = Object.keys(aimodelsAdmin).map(key => ({
        key: key.toLowerCase(),
        text: aimodelsAdmin[key],
        selected: chatModel === key,
        data: key
    }));
    const aimodel_user = Object.keys(aimodelsUser).map(key => ({
        key: key.toLowerCase(),
        text: aimodelsUser[key],
        selected: chatModel === key,
        data: key
    }));
    const popupStyles = mergeStyleSets({
        root: {
            background: "rgba(0, 0, 0, 0.2)",
            bottom: "0",
            left: "0",
            position: "fixed",
            right: "0",
            top: "0"
        },
        content: {
            background: "white",
            left: "50%",
            maxWidth: "1000px",
            padding: "0 0em 2em",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }
    });

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event as ChatAppResponse;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        return fullResponse;
    };

    const makeApiRequest = async (
        question: string,
        category?: string,
        regenerated?: boolean,
        docType?: string,
        selectedChatIndex?: number,
        isEdit?: boolean
    ) => {
        if (emptyProduct) {
            return;
        }
        const urlParams = new URLSearchParams();
        if (isChatShared && !isEdit) {
            setCurrentView("chat");
            const newSessionId = String(uuidv4());
            sessionStorage.setItem("sessionId", newSessionId);
            setSelectedSessionId(newSessionId);
            copySharedChat(sessionId);
        }
        if (regenerated) {
            setCurrentView("chat");
        }
        lastQuestionRef.current = question;
        lastAnswerRef.current = "";
        lastDataPointsRef.current = [];
        lastThoughtsRef.current = "";
        lastFollowUpRef.current = [];
        lastKeyWordRef.current = "";
        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setIsOnSave(true);
        setScrollToAnswer(true);
        setIsAnswerLoading(true);
        setSelectedAnswer(answers?.length > 0 ? answers?.length - 1 : answers?.length);
        if (newChatQuestion === "" || regenerated || isChatShared) {
            setnewChatQuestion(question);
            if (!regenerated && currentView === "history" && !isChatShared) {
                if (Object.keys(lastSessionRef.current).length !== 0) {
                    todaySessionsRef.unshift(lastSessionRef.current);
                }
                let indexToRemove = -1;
                if (lastActiveIndexRef.current === 1) {
                    const indexes = [];
                    for (let i = 0; i < lastActiveSessionRef.current.length; i++) {
                        if (lastActiveSessionRef?.current[i]?.session_id === currentIndex) {
                            indexes.push(i);
                        }
                    }
                    indexToRemove = indexes.length > 0 ? indexes[indexes.length - 1] : -1;
                } else {
                    indexToRemove = lastActiveSessionRef.current.findIndex(obj => obj.session_id === currentIndex);
                }
                if (indexToRemove !== -1) {
                    lastActiveSessionRef.current.splice(indexToRemove, 1);
                }
            } else {
                todaySessionsRef.unshift({ session_id: sessionStorage.getItem("sessionId") ?? "", title: question, conversation_date: new Date().toString() });
                setCurrentIndex(sessionStorage.getItem("sessionId") ?? "");
            }
        }
        if (IsOnEdit && selectedChatIndex === 0) {
            setShowSupportLink(false);
            todaySessionsRef[0] = { session_id: sessionStorage.getItem("sessionId") ?? "", title: question, conversation_date: new Date().toString() };
        }
        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                        exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                        language: selectedLanguage,
                        top: retrieveCount,
                        chat_model: chatModel,
                        only_citations: onlyCitations,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: useSemanticRanker,
                        semantic_captions: useSemanticCaptions,
                        suggest_followup_questions: useSuggestFollowupQuestions,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        product_filter: category
                            ? [category]
                            : selectedApp === "Manuals"
                              ? productFilter === "0" || productFilter === ""
                                  ? isAdmin
                                      ? ["0"]
                                      : appInfo.product_indexes_manuals
                                  : [productFilter]
                              : faqProductFilter === "0" || faqProductFilter === ""
                                ? isAdmin
                                    ? ["0"]
                                    : appInfo.product_indexes_faqs
                                : [faqProductFilter],
                        doctype_filter: !isWapUser ? "External" : docType ? docType : docTypeFilter,
                        sub_product_filter: selectedApp === "FAQ" ? (subProductFilter === "" ? ["0"] : [subProductFilter]) : "0",
                        template_keys: templateKeyWords,
                        product_version: selectedApp === "Manuals" ? (productVersion === "" ? "" : productVersion) : "",
                        faq_version: selectedApp === "FAQ" ? (versionFAQProduct === "" ? "" : versionFAQProduct) : "",
                        version_index: selectedApp === "Manuals" ? (productSubVersion === "" ? "" : productSubVersion) : "",
                        templateContents: selectedTemplateContent
                    }
                },
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };

            const response = await chatApi(request, selectedApp, userInfo.corp_id);
            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);
                parsedResponse.choices[0].message.content = await parseAnswerReplaceFileName(
                    parsedResponse?.choices[0].message.content,
                    parsedResponse?.choices[0].context.file_mapping
                );

                // set product_filter and chat_model
                parsedResponse.choices[0].context.app_type = selectedApp;
                parsedResponse.choices[0].context.product_filter = selectedApp === "Manuals" ? productFilter ?? "ALL" : faqProductFilter ?? "ALL";
                parsedResponse.choices[0].context.sub_product_filter = subProductFilter ?? "ALL";
                parsedResponse.choices[0].context.productVersion = productVersion ?? "";
                parsedResponse.choices[0].context.versionIndex = productSubVersion ?? "";
                parsedResponse.choices[0].context.chat_model = chatModel;
                parsedResponse.choices[0].context.document_category = docTypeFilter;
                parsedResponse.choices[0].context.faqVersion = versionFAQProduct ?? "";
                setAnswers([...answers, [question, parsedResponse]]);
                lastAnswerRef.current = parsedResponse?.choices[0].message.content;
                lastDataPointsRef.current = parsedResponse?.choices[0].context.data_points;
                lastThoughtsRef.current = parsedResponse?.choices[0].context.thoughts;
                lastKeyWordRef.current = parsedResponse?.choices[0].context.keywords;
                lastFollowUpRef.current = parsedResponse?.choices[0].context.followup_questions;
                const privateDoctype = parsedResponse?.choices[0].context.data_points.some(dataPoint => dataPoint.doctype === "Internal");
                if (privateDoctype) {
                    localStorage.setItem("private_warning", "true");
                }
                if (regenerated) {
                    setDocType(privateDoctype);
                } else if (privateDoctype && !doctype) {
                    setDocType(true);
                }
            } else {
                const parsedResponseOrError: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponseOrError.error || "Unknown error");
                }

                // set product_filter and chat_model
                const parsedResponse = parsedResponseOrError as ChatAppResponse;
                parsedResponse.choices[0].message.content = await parseAnswerReplaceFileName(
                    parsedResponse?.choices[0].message.content,
                    parsedResponse?.choices[0].context.file_mapping
                );
                parsedResponse.choices[0].context.app_type = selectedApp;
                parsedResponse.choices[0].context.product_filter = selectedApp === "Manuals" ? productFilter ?? "ALL" : faqProductFilter ?? "ALL";
                parsedResponse.choices[0].context.sub_product_filter = subProductFilter ?? "ALL";
                parsedResponse.choices[0].context.productVersion = productVersion ?? "";
                parsedResponse.choices[0].context.versionIndex = productSubVersion ?? "";
                parsedResponse.choices[0].context.chat_model = chatModel;
                parsedResponse.choices[0].context.document_category = docTypeFilter;
                parsedResponse.choices[0].context.faqVersion = versionFAQProduct ?? "";
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
                lastAnswerRef.current = parsedResponse?.choices ? parsedResponse?.choices[0].message.content : "";
                lastDataPointsRef.current = parsedResponse?.choices ? parsedResponse?.choices[0].context.data_points : [];
                lastThoughtsRef.current = parsedResponse?.choices ? parsedResponse?.choices[0].context.thoughts : "";
                lastKeyWordRef.current = parsedResponse?.choices ? parsedResponse?.choices[0].context.keywords : "";
                lastFollowUpRef.current = parsedResponse?.choices ? parsedResponse?.choices[0].context.followup_questions : [];
                const privateDoctype = parsedResponse?.choices[0].context.data_points.some(dataPoint => dataPoint.doctype === "Internal");
                if (privateDoctype) {
                    localStorage.setItem("private_warning", "true");
                }
                if (regenerated) {
                    setDocType(privateDoctype);
                } else if (privateDoctype && !doctype) {
                    setDocType(true);
                }
            }
            saveChatHistory(regenerated, selectedChatIndex);
            setIsOnEdit(false);
            urlParams.append("SessionId", sessionStorage.getItem("sessionId") ?? "");
            history.replaceState({}, "", "?" + urlParams.toString());
            if (isChatShared) {
                setIsChatShared(false);
            }
            setTemplateKeywords("");
        } catch (e) {
            setError(e);
            setIsOnSave(false);
            setIsOnEdit(false);
        } finally {
            setIsLoading(false);
            setIsAnswerLoading(false);
        }
    };
    const parseAnswerReplaceFileName = async (answer: string, file_mapping: any): Promise<string> => {
        let parsedAnswer = answer.trim();
        if (file_mapping) {
            Object.keys(file_mapping).forEach(key => {
                const regex = new RegExp(`[${key}]`, "g");
                if (parsedAnswer.includes(`[${key}]`)) {
                    parsedAnswer = parsedAnswer.replace(`${key}`, file_mapping[key]);
                } else if (parsedAnswer.includes(`${key}`)) {
                    const regex = new RegExp(key, "g");
                    parsedAnswer = parsedAnswer.replace(`${key}`, `[${file_mapping[key]}]`);
                }
            });
        }
        if (isStreaming) {
            let lastIndex = parsedAnswer.length;
            for (let i = parsedAnswer.length - 1; i >= 0; i--) {
                if (parsedAnswer[i] === "]") {
                    break;
                } else if (parsedAnswer[i] === "[") {
                    lastIndex = i;
                    break;
                }
            }
            const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
            parsedAnswer = truncatedAnswer;
        }
        const replaceTempfileWithActual = (part: string): string => {
            const replacedPart = part.replace(/tempfile\d+\.(pdf|txt)/g, match => file_mapping[match] || match);
            return `[${replacedPart}] `;
        };
        const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
        const fragments: string[] = parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            } else {
                return replaceTempfileWithActual(part);
            }
        });
        return fragments.join("");
    };
    const saveChatHistory = async (isRegenerated?: boolean, selectedChatIndex?: number) => {
        try {
            const lastReference: any = {
                title: lastQuestionRef.current,
                product: selectedApp === "Manuals" ? productFilter ?? "ALL" : faqProductFilter ?? "ALL",
                doctype: docTypeFilter,
                response_data: {
                    user: lastQuestionRef.current,
                    bot: lastAnswerRef.current,
                    data_points: lastDataPointsRef.current,
                    thoughts: lastThoughtsRef.current,
                    followup_questions: lastFollowUpRef.current
                },
                request_options: {
                    top: retrieveCount,
                    chat_model: chatModel,
                    language: selectedLanguage,
                    only_citations: onlyCitations,
                    retrieval_mode: retrievalMode,
                    semantic_ranker: useSemanticRanker,
                    semantic_captions: useSemanticCaptions,
                    suggest_followup_questions: useSuggestFollowupQuestions,
                    use_oid_security_filter: useOidSecurityFilter,
                    use_groups_security_filter: useGroupsSecurityFilter
                },
                keyword: lastKeyWordRef.current,
                apptype: selectedApp,
                retrival_mode: retrievalMode,
                fromHistory: !isRegenerated && currentView === "history",
                sessionId: selectedSessionId
            };
            if (selectedApp === "FAQ") {
                lastReference["sub_product"] = subProductFilter;
                lastReference["faq_version"] = versionFAQProduct;
            }
            if (selectedApp === "Manuals") {
                lastReference["product_version"] = productVersion;
                lastReference["version_index"] = productSubVersion;
            }
            if (isChatShared) {
                lastReference.fromHistory = false;
                setIsChatShared(false);
            }
            await saveChatApi(lastReference)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    let updatedRecords = [...conversationDetails];
                    if (IsOnEdit) {
                        updatedRecords[selectedQuestion] = {
                            userName: userName,
                            conversationId: result?.conv_id,
                            groupId: result?.groupId,
                            title: result?.title,
                            conversationDate: result?.conversationDate
                        };
                    } else {
                        updatedRecords = [
                            ...conversationDetails,
                            {
                                userName: userName,
                                conversationId: result?.conv_id,
                                groupId: result?.groupId,
                                title: result?.title,
                                conversationDate: result?.conversationDate
                            }
                        ];
                    }
                    setConversationDetails(updatedRecords);
                    let key = 0;
                    if (currentView === "history") {
                        key = answers.length > 0 ? answers.length : 0;
                    } else {
                        key = primaryIDMap.size > 0 ? primaryIDMap.size : 0;
                    }
                    primaryIDMap.set(key, result?.conv_id);
                    setIsOnSave(false);
                    const selectedIndex = selectedChatIndex ?? 0;
                    if (lastDataPointsRef?.current?.length > 0) {
                        setActiveAnalysisPanelTab(AnalysisPanelTabs.SupportingContentTab);
                        if (IsOnEdit) {
                            setSelectedAnswer(selectedIndex);
                        } else {
                            setSelectedAnswer(key);
                        }
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    setIsOnSave(false);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const loadChatData = async (sessionId: string) => {
        try {
            setSelectedTemplateContent("");
            setIsAnswerLoading(true);
            setError(undefined);
            setConversationDetails([]);
            setCurrentView("history");
            setnewChatQuestion("");
            setDocType(false);
            setIsOnEdit(false);
            setShowSupportLink(false);
            await loadChatApi(sessionId)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    setConversationDetails([]);
                    for (let i = 0; i < result.answers.length; i++) {
                        setConversationDetails(prevConversationId => [
                            ...prevConversationId,
                            {
                                userName: userName,
                                conversationId: result.chatidMap[i],
                                groupId: sessionId,
                                title: result.answers[i][0],
                                conversationDate: result.answers[i][1].choices[0].context.feedback.c_date
                            }
                        ]);
                    }

                    const chatidMap = result.chatidMap as Record<string, string>;
                    for (const [key, convId] of Object.entries(chatidMap)) {
                        primaryIDMap.set(Number(key), convId);
                    }
                    setScrollToAnswer(true);
                    // setSelectedAnswer(0);
                    setSelectedCitation(0);
                    setSelectedAnswer(result?.answers?.length > 0 ? result?.answers?.length - 1 : result?.answers?.length);
                    setAnswers([...result.answers]);
                    let privateDoctype = false;
                    result.answers.forEach((answer: any) => {
                        if (answer[1].choices[0].context.data_points.some((dataPoint: { doctype: string }) => dataPoint.doctype === "Internal")) {
                            privateDoctype = true;
                            return true;
                        }
                    });
                    if (privateDoctype) {
                        localStorage.setItem("private_warning", "true");
                    }
                    setDocType(privateDoctype);
                    setSelectedSessionId(sessionId);
                    setDeveloperSettings(result.settings);
                    setOptions(result.options);
                    if (result?.answers[0] && result?.answers[0][1]?.choices[0]?.context?.data_points?.length > 0) {
                        setActiveAnalysisPanelTab(AnalysisPanelTabs.SupportingContentTab);
                    } else {
                        setActiveAnalysisPanelTab(undefined);
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsAnswerLoading(false);
        }
    };

    const setDeveloperSettings = async (settings: DeveloperOptions) => {
        try {
            setRetrieveCount(settings.top);
            setChatModel(settings.chat_model);
            setOnlyCitations(settings.only_citations);
            setRetrievalMode(settings.retrieval_mode);
            setUseSemanticRanker(settings.semantic_ranker);
            setUseSemanticCaptions(settings.semantic_captions);
            setUseSuggestFollowupQuestions(settings.suggest_followup_questions);
        } catch (error) {
            console.error("Error on setting developer options data:", error);
        }
    };

    const setOptions = async (options: DataOptions) => {
        try {
            if (options?.app_type) {
                if (selectedApp !== options?.app_type) {
                    loadProductFilter(options?.app_type, true);
                }
                setSelectedApp(options?.app_type);
            }
            setChatModel(
                options.chat_model in aimodelsAdmin ? options.chat_model : options?.app_type === "Manuals" ? defaultManualsAiModel : defaultFaqAiModel
            );
            if (options?.app_type === "Manuals") {
                setManualsFilterText(options.product_filter, options.productVersion, options.versionIndex);
            } else {
                setFAQFilterText(options.product_filter, options.faqVersion, options?.sub_product_filter);
            }
            const product_categories = options?.app_type === "FAQ" ? appInfo.faq_categories[selectedLocale] : appInfo.product_manual_categories[selectedLocale];
            const productIndex = product_categories.findIndex((item: any) => item.key === options.product_filter);
            if (options?.product_filter?.startsWith("key0") || productIndex < 0) {
                setApplyRestriction(true);
            } else {
                setApplyRestriction(false);
            }
            setDocTypeFilter(options.doctype_filter);
        } catch (error) {
            console.error("Error on setting developer options data:", error);
        }
    };

    const setFAQFilterText = (prod_filter: string | undefined, productVersion: string | undefined, sub_product_filter: string | undefined) => {
        if (!prod_filter || !sub_product_filter) {
            return;
        }
        if (prod_filter === "" && sub_product_filter === "") {
            return;
        }

        const product = appInfo?.faq_categories[selectedLocale]?.filter((item: any) => item.key === prod_filter);
        if (product?.length > 0) {
            setFaqProductFilter(prod_filter);
            setProductText(prod_filter !== "0" ? product[0]?.text : isAdmin ? "ALL" : `${i18next.t("chat.productFilterText")}`);

            if (productVersion !== "" && product[0]?.versions?.length > 0) {
                const selectedVersion = product[0]?.versions?.filter((item: any) => item.key === productVersion)[0];
                setVersionFAQProduct(productVersion ?? "");
                setVersionFAQProductText(productVersion === "0" ? "ALL" : selectedVersion?.text ?? "");
                if (sub_product_filter !== "") {
                    const subVersion = selectedVersion?.sub_versions?.find((sv: any) => sv.key === sub_product_filter);
                    setSubProductFilter(sub_product_filter ?? "");
                    setSubProductText(sub_product_filter === "0" ? "ALL" : subVersion?.text ?? "");
                } else {
                    setSubProductFilter("0");
                    setSubProductText("ALL");
                }
            } else if (sub_product_filter !== "") {
                if (product[0]?.versions?.length > 0) {
                    setVersionFAQProduct("");
                    setVersionFAQProductText("");
                    setSubProductFilter("0");
                    setSubProductText("ALL");
                } else {
                    setSubProductFilter(sub_product_filter);
                    setSubProductText(
                        sub_product_filter !== "0" ? product[0]?.data?.filter((item: any) => item.key === sub_product_filter)[0]?.text ?? "" : "ALL"
                    );
                }
            } else {
                setVersionFAQProduct("");
                setVersionFAQProductText("");
                setSubProductFilter("");
                setSubProductText("");
            }
        } else {
            setFaqProductFilter(isAdmin ? "0" : "");
            setProductText(isAdmin ? "ALL" : `${i18next.t("chat.productFilterText")}`);
            setSubProductFilter(isAdmin ? "0" : "");
            setSubProductText(isAdmin ? "ALL" : `${i18next.t("chat.subProduct")}`);
        }
    };

    const setManualsFilterText = (prod_filter: string | undefined, productVersion: string | undefined, versionIndex: string | undefined) => {
        const product = appInfo?.product_manual_categories[selectedLocale]?.filter((item: any) => item.key === prod_filter);
        if (product?.length > 0) {
            setProductFilter(prod_filter);
            setManualsProductText(prod_filter !== "0" ? product[0]?.text : isAdmin ? "ALL" : `${i18next.t("chat.productFilterText")}`);
            if (productVersion !== "" && product[0]?.versions?.length > 0) {
                const selectedVersion = product[0]?.versions?.filter((item: any) => item.key === productVersion)[0];
                setProductVersion(productVersion ?? "");
                setProductVersionText(productVersion === "0" ? "ALL" : selectedVersion?.text ?? "");
                if (versionIndex !== "") {
                    const subVersion = selectedVersion?.sub_versions?.find((sv: any) => sv.key === versionIndex);
                    setProductSubVersion(versionIndex ?? "");
                    setProductSubVersionText(versionIndex === "0" ? "ALL" : subVersion?.text ?? "");
                } else {
                    setProductSubVersion("0");
                    setProductSubVersionText("ALL");
                }
            } else {
                setProductVersion("");
                setProductVersionText("");
                setProductSubVersion("");
                setProductSubVersionText("");
            }
        } else {
            setProductFilter(isAdmin ? "0" : "");
            setManualsProductText(isAdmin ? "ALL" : `${i18next.t("chat.productFilterText")}`);
        }
    };

    const loadSessionData = async (sessionId: string) => {
        try {
            await loadSessionApi(nextDateToLoad)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (nextDateToLoad === "") {
                        setTodaySessionsRef(result["today"] ?? []);
                        setLast7SessionsRef(result["7days"] ?? []);
                        setLast30SessionsRef(result["30days"] ?? []);
                    }
                    setMonthlySessions(monthlySessions?.length > 0 ? [...monthlySessions, ...result["months"]] : result["months"] ?? []);
                    setNextDateToLoad(result["nextDate"] ?? "");
                    // setAnswers([]);
                })
                .then(() => {
                    if (sessionId != "") {
                        loadChatData(sessionId);
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const saveUserSettings = async (
        docValue: string,
        productValue: string,
        locale: string,
        aimodel: string,
        isSidebarOpen: string,
        apptype: string,
        faqProduct: string,
        subProduct: string,
        chatTourDone: string,
        answerTourDone: string,
        productVersion: string,
        versionIndex: string,
        templateTourDone: string,
        faqVersion: string
    ) => {
        try {
            await saveUserDetails(
                docValue,
                productValue,
                locale,
                aimodel,
                isSidebarOpen,
                apptype,
                faqProduct,
                subProduct,
                chatTourDone,
                answerTourDone,
                productVersion,
                versionIndex,
                templateTourDone,
                faqVersion
            )
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    const setAppTitle = (app: string) => {
        document.title = app === "FAQ" ? i18next.t("layout.faqAppName") : i18next.t("layout.manualAppName");
    };

    const loadProductFilter = async (newApp: string, isToggleClick: boolean) => {
        try {
            const product_categories = newApp === "FAQ" ? appInfo.faq_categories[selectedLocale] : appInfo.product_manual_categories[selectedLocale];
            const allIndex = product_categories.findIndex((item: any) => item.key === "ALL");

            if (allIndex > -1) {
                const allItem = product_categories.splice(allIndex, 1)[0];
                product_categories.unshift(allItem);
            }

            if (isToggleClick) {
                if (newApp === "Manuals") {
                    if (faqProductFilter !== "") {
                        const selectedProduct = appInfo.faq_categories[selectedLocale]?.filter((item: any) => item.key === faqProductFilter);
                        const toSelect = appInfo.product_manual_categories[selectedLocale]?.filter((item: any) => selectedProduct[0].text === item.text);
                        if (toSelect && toSelect.length > 0) {
                            setProductFilter(toSelect[0].key);
                            saveUserSettings("", toSelect[0].key, "", "", "", "", "", "", "", "", "", "", "", "");
                            setManualsProductText(toSelect[0].text);
                            shouldDisableInputBox(newApp, toSelect[0].key, toSelect[0].key === "0" ? true : false);
                        } else {
                            setProductFilter(isAdmin ? "0" : "");
                            saveUserSettings("", isAdmin ? "0" : "", "", "", "", "", "", "", "", "", "", "", "", "");
                            setManualsProductText(isAdmin ? "ALL" : `${i18next.t("chat.productFilterText")}`);
                        }
                        if (toSelect && toSelect[0]?.versions?.length > 0) {
                            setProductVersion("0");
                            setProductVersionText("ALL");
                            setProductSubVersion("0");
                            setProductSubVersionText("ALL");
                        } else {
                            setProductVersion("");
                            setProductVersionText("");
                            setProductSubVersion("");
                            setProductSubVersionText("");
                        }
                    }
                } else {
                    if (productFilter !== "") {
                        const selectedProduct = appInfo.product_manual_categories[selectedLocale]?.filter((item: any) => item.key === productFilter);
                        const toSelect = appInfo.faq_categories[selectedLocale]?.filter((item: any) => selectedProduct[0].text === item.text);
                        if (toSelect && toSelect.length > 0) {
                            setFaqProductFilter(toSelect[0].key);
                            setSubProductFilter("0");
                            saveUserSettings("", "", "", "", "", "", toSelect[0].key, "0", "", "", "", "", "", "0");
                            setProductText(toSelect[0].text);
                            setSubProductText("ALL");
                            shouldDisableInputBox(newApp, toSelect[0].key, toSelect[0].key === "0" ? true : false);
                            if (toSelect[0]?.versions?.length > 0) {
                                setVersionFAQProduct("0");
                                setVersionFAQProductText("ALL");
                            } else {
                                setVersionFAQProduct("");
                                setVersionFAQProductText("");
                            }
                        } else {
                            setFaqProductFilter(isAdmin ? "0" : "");
                            setSubProductFilter("0");
                            setIsHovered(-1);
                            setIsSubHovered(-1);
                            saveUserSettings("", "", "", "", "", "", isAdmin ? "0" : "", "0", "", "", "", "", "", "");
                        }
                    }
                }
            }

            // Sort categories based on suffix: "" -> "移行前" -> "CONVERSION"
            const sortedCategories = product_categories.sort((a: any, b: any) => {
                const suffixA = a.text.endsWith("移行前") ? 1 : a.text.endsWith("CONVERSION") ? 2 : 0;
                const suffixB = b.text.endsWith("移行前") ? 1 : b.text.endsWith("CONVERSION") ? 2 : 0;
                return suffixA - suffixB;
            });

            setProducts(sortedCategories);
            const subProducts = newApp === "FAQ" ? product_categories.filter((data: any) => data.key === userInfo?.faqProduct) : [];
            setSubProducts([{ key: "0", text: "ALL" }, ...(subProducts[0]?.data ?? [])]);
        } catch (error) {
            console.error("Error assigning data from appInfo:", error);
        }
    };

    const loadDocCategories = async () => {
        try {
            if (isWapUser) {
                const productsValues = Object.keys(appInfo.wap_doc_categories).map(key => ({
                    key,
                    text: i18next.t(`chat.${appInfo.wap_doc_categories[key]}`)
                }));
                setCategories(productsValues);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const onFeedbackSubmitClicked = async (feedback: Feedback) => {
        error && setError(undefined);
        try {
            const chat_id = primaryIDMap.get(lastAnswerIndexRef.current) ? primaryIDMap.get(lastAnswerIndexRef.current) : "";
            const request: SaveResponse = {
                feedbacks: feedback
            };
            const response = await feedbackApi(request, chat_id);
            if (!response.body) {
                throw Error("No response body");
            }
        } catch (e) {
            setError(e);
        } finally {
            console.log("Feedback Save block end");
        }
    };

    const clearChat = () => {
        setDocType(false);
        setShowSupportLink(false);
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        lastAnswerRef.current = "";
        lastDataPointsRef.current = [];
        lastThoughtsRef.current = "";
        lastAnswerIndexRef.current = -1;
        createSession();

        primaryIDMap = new Map();
        setnewChatQuestion("");
        setCurrentView("chat");
        setCurrentIndex("");
        history.replaceState({}, "", "/assistant/chat");
        setConversationDetails([]);
        //setSelectedTemplateContent("");
        setClearChatTriggered(true);
        setChatContentCleared(false);
    };

    const regenerate = () => {
        createSession();
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setShowSupportLink(false);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        lastAnswerRef.current = "";
        lastDataPointsRef.current = [];
        lastThoughtsRef.current = "";
        lastAnswerIndexRef.current = -1;
        primaryIDMap = new Map();
        setnewChatQuestion("");
        setCurrentView("chat");
        setCurrentIndex("");
        const regenerateQues = answers ? answers[0][0] : streamedAnswers[0][0];
        answers.length = 0;
        streamedAnswers.length = 0;
        const sessionId = uuidv4();
        sessionStorage.setItem("sessionId", sessionId);
        makeApiRequest(regenerateQues, selectedApp === "Manuals" ? productFilter : faqProductFilter, true);
    };

    const createSession = () => {
        // create the session ID & set to session storage
        const sessionId = uuidv4();
        sessionStorage.setItem("sessionId", sessionId);
    };

    const showWarning = () => {
        localStorage.setItem("show_warning", "false");
        setWarningShown(false);
    };

    const updateModelData = () => {
        const storedModel = userInfo?.aimodel;
        if (storedModel && storedModel !== "") {
            if (storedModel === ChatModel.GPT_4O_MINI) {
                setChatModel(ChatModel.GPT_4O_MINI);
            } else if (storedModel === ChatModel.GPT_4O) {
                setChatModel(ChatModel.GPT_4O);
            }
        } else {
            if (selectedApp === "Manuals") {
                setChatModel(defaultManualsAiModel);
            } else {
                setChatModel(defaultFaqAiModel);
            }
        }
    };

    useEffect(() => {
        if (localStorage.getItem("private_warning") === null) {
            localStorage.setItem("private_warning", "false");
        }
        if (localStorage.getItem("show_warning") === null) {
            localStorage.setItem("show_warning", "true");
        }
        setProductFilter(userInfo?.product === "" ? (isAdmin ? "0" : "") : userInfo?.product);
        if (userInfo?.docValue) {
            setDocTypeFilter(userInfo?.docValue);
        }
        setFAQFilterText(userInfo?.faqProduct, userInfo.faqVersion, userInfo?.subProduct);
        setManualsFilterText(userInfo?.product, userInfo?.productVersion, userInfo?.versionIndex);
        if (userInfo?.apptype === "Manuals") {
            shouldDisableInputBox(userInfo?.apptype, userInfo?.product, userInfo?.product === "0" ? true : false);
        } else if (userInfo?.apptype === "FAQ") {
            shouldDisableInputBox(userInfo?.apptype, userInfo?.faqProduct, userInfo?.faqProduct === "0" ? true : false);
        }
        if (userInfo?.apptype === "") {
            shouldDisableInputBox("FAQ", userInfo?.faqProduct ?? "0", true);
        } else {
            if (validateProducts(userInfo?.apptype, false) === false) {
                setApplyRestriction(true);
            }
        }
        updateModelData();
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading];
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers];
        setAppTitle(selectedApp);
        loadProductFilter(selectedApp, false);
        loadDocCategories();
        createSession();
        loadSessionData("");
        primaryIDMap = new Map();
        setnewChatQuestion("");
        const queryParams = parseQueryParams(location.search);
        const sessionValue = queryParams.SessionId;
        setSessionId(sessionValue);
        if (sessionValue != null && sessionValue != undefined && sessionValue != "") {
            loadSharedChat(sessionValue);
            setCurrentView("chat");
        } else {
            clearChat();
        }
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (props.executeClear) {
            clearChat();
            props.callback();
        }
    }, [props.executeClear]);

    function parseQueryParams(queryvalue: string) {
        const queryparameter = new URLSearchParams(queryvalue);
        const params: { [key: string]: string } = {};

        for (const [key, value] of queryparameter.entries()) {
            params[key] = value;
        }

        return params;
    }

    const loadSharedChat = async (sessionvalue: string) => {
        sessionStorage.setItem("sessionId", sessionvalue);
        try {
            await loadSharedChatApi(sessionvalue, userInfo.corp_id)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result != null && result != undefined && result != "" && result.answers.length > 0) {
                        if (userName != result.options.userId) {
                            setIsChatShared(true);
                        } else {
                            setIsChatShared(false);
                        }
                        if (result.options.shared_chat == true || result.options.userId == userName) {
                            loadSessionData(sessionvalue);
                            setCurrentIndex(sessionvalue ?? "");
                        } else {
                            clearChat();
                        }
                    } else {
                        clearChat();
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const copySharedChat = async (sessionvalue: string) => {
        try {
            const newSessionId = sessionStorage.getItem("sessionId");
            await copySharedChatApi(sessionvalue, newSessionId)
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue ?? "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue ?? "3"));
    };

    const onChatModelChange = (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<ChatModel> | undefined) => {
        setChatModel(option?.data ?? ChatModel.GPT_4O_MINI);
        saveUserSettings("", "", "", option?.data ?? ChatModel.GPT_4O_MINI, "", "", "", "", "", "", "", "", "", "");
        userInfo.aimodel = option?.data ?? ChatModel.GPT_4O_MINI;
    };

    const onOnlyCitationsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setOnlyCitations(!!checked);
        setShouldStream(!checked);
        setUseSuggestFollowupQuestions(false);
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined) => {
        setRetrievalMode(option?.data ?? RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue ?? "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const onExampleClicked = (example: string, category: string, docType: string) => {
        if (selectedApp === "Manuals") {
            setProductFilter(category);
        } else {
            setFaqProductFilter(category);
        }
        const urlParams = new URLSearchParams();
        urlParams.append("SessionId", sessionStorage.getItem("sessionId") ?? "");
        history.replaceState({}, "", "?" + urlParams.toString());
        setDocTypeFilter(docType);
        makeApiRequest(example, category, false, docType);
    };

    const onShowCitation = (citation: string, citationIndex: number, index: number) => {
        setActiveCitation(citation);
        setActiveAnalysisPanelTab(AnalysisPanelTabs.SupportingContentTab);
        setSelectedAnswer(index);
        setSelectedCitation(citationIndex);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        setSelectedCitation(0);
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const updateStreamedAnswer = (index: number, updatedAnswer: ChatAppResponse) => {
        const updatedStreamedAnswers = [...streamedAnswers];
        if (index >= 0 && index < updatedStreamedAnswers.length) {
            updatedStreamedAnswers[index][1] = updatedAnswer;
            setStreamedAnswers(updatedStreamedAnswers);
        }
        const updatedanswers = [...answers];
        if (index >= 0 && index < updatedanswers.length) {
            updatedanswers[index][1] = updatedAnswer;
            setAnswers(updatedanswers);
        }
    };

    const tabstyles = useStyles();
    const hidetoken = false;

    // dropdown test
    const [isMainDropdownOpen, setIsMainDropdownOpen] = useState<boolean>(false);
    const [isSubDropdownOpen, setIsSubDropdownOpen] = useState<boolean>(false);
    const [productText, setProductText] = useState<string>(`${i18next.t("chat.productFilterText")}`);
    const [subProductText, setSubProductText] = useState<string>(`${i18next.t("chat.subProduct")}`);
    const mainDropdownRef = useRef<HTMLDivElement | null>(null);

    const [isManualsDropdownOpen, setIsManualsDropdownOpen] = useState<boolean>(false);
    const [manualsProductText, setManualsProductText] = useState<string>(`${i18next.t("chat.productFilterText")}`);
    const manualsDropdownRef = useRef<HTMLDivElement | null>(null);
    const [productVersion, setProductVersion] = useState<string>("");
    const [productSubVersion, setProductSubVersion] = useState<string>("");
    const [productVersionText, setProductVersionText] = useState<string>("");
    const [productSubVersionText, setProductSubVersionText] = useState<string>("");
    const [isVersionsOpen, setIsVersionsOpen] = useState<boolean>(false);
    const [isSubVersionsOpen, setIsSubVersionsOpen] = useState<boolean>(false);
    const [versions, setVersions] = useState<{ key: string; text: string; versions: any }[] | IDropdownOption[]>([]);
    const [subVersions, setSubVersions] = useState<{ key: string; text: string }[] | IDropdownOption[]>([]);
    const [lastVerHoveredRef, setLastVerHoveredRef] = useState<any>(undefined);
    const [versionFAQProduct, setVersionFAQProduct] = useState<string>("");
    const [versionFAQProductText, setVersionFAQProductText] = useState<string>("");
    const [isFAQVersionsOpen, setIsFAQVersionsOpen] = useState<boolean>(false);
    const [lastVerFAQHoveredRef, setLastVerFAQHoveredRef] = useState<any>(undefined);
    const [subFAQVersions, setSubFAQVersions] = useState<{ key: string; text: string }[] | IDropdownOption[]>([]);
    const divFAQRefs = useRef<(HTMLDivElement | null)[]>([]);
    const divVerFAQRefs = useRef<(HTMLDivElement | null)[]>([]);

    const divManualsRefs = useRef<(HTMLDivElement | null)[]>([]);
    const divVerManualsRefs = useRef<(HTMLDivElement | null)[]>([]);
    const handleClickOutside = (event: { target: any }) => {
        if (mainDropdownRef.current && !mainDropdownRef.current.contains(event.target)) {
            setIsMainDropdownOpen(false);
            setIsSubDropdownOpen(false);
            setIsFAQVersionsOpen(false);
        }
        if (manualsDropdownRef.current && !manualsDropdownRef.current.contains(event.target)) {
            setIsManualsDropdownOpen(false);
            setIsVersionsOpen(false);
            setIsSubVersionsOpen(false);
        }
    };

    const templateContents = useSelector((store: RootState) => store.templateContents.templateContents);
    const selectedProductKey = selectedApp === "Manuals" ? manualsProductText : selectedApp === "FAQ" ? productText : "";
    const selectedProductId = userInfo.userFavourite?.[selectedProductKey] ?? "";
    const selectedProduct = useSelector((store: RootState) => store.templateContents.selectedProduct);
    let faviTemplateId = templateContents.filter((temp: any) => temp.template[0].isDefault === true).map((temp: any) => temp.id);
    const userDefaultContent =
        templateContents
            .filter((tempContent: any) => tempContent.id === selectedProductId)
            .map((tempContent: any) => {
                return tempContent.template[0].previewPrompt;
            })[0] || "";

    const systemDefaultContent =
        selectedProduct === "ALL"
            ? templateContents
                  .filter((tempContent: any) => tempContent.product && tempContent.product === selectedProduct)
                  .filter((AllContent: any) => AllContent.template?.[0]?.isDefault === true)?.[0]
            : templateContents
                  .filter((tempContent: any) => tempContent.template?.[0].isDefault === true)
                  .map((tempContent: any) => tempContent.template[0].previewPrompt)[0] || "";

    dispatch(setUserDefaultContentInStore(userDefaultContent));
    dispatch(
        setSystemDefaultContentInStore(
            selectedProduct === "ALL" ? (systemDefaultContent ? systemDefaultContent.template[0].previewPrompt : "") : systemDefaultContent
        )
    );
    dispatch(setDefaultTemplateIdInStore(faviTemplateId[0]));

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleMainDropdownClick = async () => {
        const isValid = await validateProducts("FAQ", false);
        if (isValid) {
            setIsMainDropdownOpen(!isMainDropdownOpen);
            setIsHovered(-1);
            setIsSubHovered(-1);
            setIsSubFAQVersionsHovered(-1);
            const product = appInfo?.faq_categories[selectedLocale]?.filter((item: any) => item.key === faqProductFilter);
            if (product?.length > 0) {
                if (versionFAQProduct !== "" && product[0]?.versions?.length > 0) {
                    setSubFAQVersions([{ key: "0", text: "ALL" }, ...(product[0]?.versions ?? [])]);
                    setIsFAQVersionsOpen(true);
                    if (subProductFilter !== "" && versionFAQProduct !== "0") {
                        const subVersion = product[0]?.versions?.filter((item: any) => item.key === versionFAQProduct);
                        setSubProducts([{ key: "0", text: "ALL" }, ...(subVersion[0]?.sub_versions ?? [])]);
                        setIsSubDropdownOpen(true);
                    }
                } else if (subProductFilter !== "" && product[0]?.data?.length > 0) {
                    setSubProducts([{ key: "0", text: "ALL" }, ...(product[0]?.data ?? [])]);
                    setIsSubDropdownOpen(true);
                } else {
                    setSubProducts([]);
                    setSubFAQVersions([]);
                    setIsSubDropdownOpen(false);
                    setIsFAQVersionsOpen(false);
                }
            }
        }
    };

    const handleManualsDropdownClick = async () => {
        const isValid = await validateProducts("Manuals", false);
        if (isValid) {
            setIsManualsDropdownOpen(!isManualsDropdownOpen);
            setIsHovered(-1);
            setIsSubHovered(-1);
            setIsSubVersionsHovered(-1);
            const product = appInfo.product_manual_categories[selectedLocale]?.filter((item: any) => item.key === productFilter);
            if (product?.length > 0) {
                if (productVersion !== "" && product[0]?.versions?.length > 0) {
                    setVersions([{ key: "0", text: "ALL" }, ...(product[0]?.versions ?? [])]);
                    setIsVersionsOpen(true);
                    if (productSubVersion !== "" && productVersion !== "0") {
                        const subVersion = product[0]?.versions?.filter((item: any) => item.key === productVersion);
                        setSubVersions([{ key: "0", text: "ALL" }, ...(subVersion[0]?.sub_versions ?? [])]);
                        setIsSubVersionsOpen(true);
                    }
                } else {
                    setVersions([]);
                    setSubVersions([]);
                    setIsVersionsOpen(false);
                    setIsSubVersionsOpen(false);
                }
            }
        }
    };

    function validateProducts(selectedApps: string, checkKeys: boolean): boolean {
        const dataSource = selectedApps === "Manuals" ? appInfo.product_manual_categories : appInfo.faq_categories;
        const validProducts = dataSource[selectedLocale]?.filter((item: any) => (!checkKeys || !item?.key?.startsWith("key0")) && item.text !== "ALL");

        return Boolean(validProducts?.length);
    }

    const [isHovered, setIsHovered] = useState(-1);
    const [isSubHovered, setIsSubHovered] = useState(-1);
    const [isSubVersionsHovered, setIsSubVersionsHovered] = useState(-1);
    const [isSubFAQVersionsHovered, setIsSubFAQVersionsHovered] = useState(-1);

    const shouldDisableInputBox = (app: string, filter: string | undefined, checkAll: boolean) => {
        const shouldApplyRestriction = checkAll ? !validateProducts(app, true) || filter?.startsWith("key0") || false : filter?.startsWith("key0") || false;
        setApplyRestriction(shouldApplyRestriction);
    };

    const answerAreaRef = useRef<HTMLDivElement>(null);
    const chatResponseAreaRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (answerAreaRef.current) {
            if (scrollToAnswer) {
                answerAreaRef.current.scrollTop = answerAreaRef.current.scrollHeight;
            } else {
                answerAreaRef.current.scrollTop = 0;
            }
        }
    }, [streamedAnswers, answers, isStreaming, error, isLoading]);

    useEffect(() => {
        if (!isAnswerLoading) return;

        // Prevent the user from reloading/closing the tab while the answer is loading
        function handleOnBeforeUnload(event: BeforeUnloadEvent) {
            event.preventDefault();
            return (event.returnValue = "");
        }
        window.addEventListener("beforeunload", handleOnBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleOnBeforeUnload);
        };
    }, [isAnswerLoading]);

    useEffect(() => {
        setSelectedTemplateContent("");
        setChatContentCleared(false);
    }, [productFilter, faqProductFilter]);

    const clearWarning = () => {
        setEmptyProduct(false);
    };

    const handleJoyrideCallback = (data: any) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            endTourOne();
        }
    };

    const calculateMainDivWidth = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth <= 1400) {
            return "-20vh";
        } else {
            return "-30vh";
        }
    };

    const handleTemplateIconClick = () => {
        setHideDynamicTemplatePopup(!hideDynamicTemplatePopup);
    };

    const handleTemplateClick = (templateId: number) => {
        setSelectedTemplate(templateId);
    };

    const handleTemplateContents = (contents: any) => {
        const textContent = contents;

        setSelectedTemplateContent(textContent);
        handleTemplateIconClick();

        const keyvalues = Object.values(contents.fields).join(" ");
        setTemplateKeywords(keyvalues);
    };

    const handleClearTemplateContent = () => {
        setSelectedTemplateContent("");
    };

    interface TourState {
        run: boolean;
        steps: Step[];
    }

    const handleTopPosition = (divReference: any, index: number, itemsLength: number) => {
        const hoveredDiv = divReference.current[index];
        if (hoveredDiv) {
            if (hoveredDiv.getBoundingClientRect().top + itemsLength * 33 > window.innerHeight - 99) {
                let remHeight = window.innerHeight - 200 - hoveredDiv.getBoundingClientRect().top;
                if (itemsLength * 33 > window.innerHeight - 200 || (itemsLength * 33 > window.innerHeight - 200 && (itemsLength * 33) / 2 > remHeight)) {
                    return 0;
                }

                if (itemsLength * 33 < window.innerHeight - 200) {
                    if (hoveredDiv.getBoundingClientRect().top - (itemsLength * 33 - remHeight) - 100 > 45)
                        return hoveredDiv.getBoundingClientRect().top - (itemsLength * 33 - remHeight) - 100;
                }
                return 0;
            } else {
                return hoveredDiv.getBoundingClientRect().top - 130;
            }
        } else {
            return 0;
        }
    };

    const [tourTemplate, setTourTemplate] = useSetState<TourState>({
        run: userInfo.templateTourDone === "false" && userInfo.chatTourDone === "true" ? true : false,
        steps: [
            {
                content: (
                    <div>
                        <Link to={AIWorks_Guides + "#page=7"} target="_blank" style={{ color: "#0004fa" }}>
                            {i18next.t(`appTour.templateTour.templatePrompt`)}
                        </Link>
                    </div>
                ),
                target: "#TemplateIcon",
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                }
            }
        ]
    });

    const handleJoyrideCallbackForTemplate = (data: any) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            endTourTemplate();
        }
    };

    const endTourTemplate = useCallback(() => {
        setTourTemplate(prevState => ({
            ...prevState,
            run: false
        }));
        if (userInfo.templateTourDone !== "true") {
            saveUserSettings("", "", "", "", "", "", "", "", "true", "true", "", "", "true", "");
        }
        userInfo.templateTourDone = "true";
    }, []);

    const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSnackOpen(false);
    };

    const handleSnackBar = () => {
        setIsSnackOpen(!isSnackOpen);
    };

    return (
        <div className={styles.overall_div}>
            {!isLoading && isAnswerLoading && !isStreaming && <Spinner />}
            <Joyride
                continuous
                steps={tourOne.steps}
                callback={handleJoyrideCallback}
                run={tourOne.run}
                hideCloseButton
                scrollToFirstStep
                showSkipButton
                disableCloseOnEsc
                disableOverlayClose
                disableScrolling
                locale={{
                    next: i18next.t(`appTour.next`),
                    skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                    back: i18next.t(`appTour.back`),
                    last: i18next.t(`appTour.last`)
                }}
                spotlightPadding={1}
                styles={{
                    options: {
                        zIndex: 10000,
                        width: windowSize.width < 1000 ? "200px" : "380px"
                    },
                    tooltip: {
                        padding: windowSize.width < 1000 ? "10px" : "15px",
                        fontSize: windowSize.width < 1000 ? "10px" : "16px"
                    },
                    tooltipContent: {
                        fontSize: windowSize.width < 1000 ? "10px" : "16px"
                    }
                }}
            />
            <Joyride
                continuous
                steps={tourTemplate.steps}
                callback={data => handleJoyrideCallbackForTemplate(data)}
                run={tourTemplate.run}
                hideCloseButton
                scrollToFirstStep
                showSkipButton
                disableCloseOnEsc
                disableOverlayClose
                disableScrolling
                locale={{
                    next: i18next.t(`appTour.next`),
                    skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                    back: i18next.t(`appTour.back`),
                    last: i18next.t(`appTour.last`)
                }}
                spotlightPadding={1}
                styles={{
                    options: {
                        zIndex: 1000000,
                        width: window.innerWidth < 1000 ? "200px" : "380px"
                    },
                    tooltip: {
                        padding: window.innerWidth < 1000 ? "10px" : "15px",
                        fontSize: window.innerWidth < 1000 ? "10px" : "16px"
                    },
                    tooltipContent: {
                        fontSize: window.innerWidth < 1000 ? "10px" : "16px"
                    }
                }}
            />
            <Sidebar
                sourcePage={"chat"}
                todaySessionsRef={todaySessionsRef}
                last7SessionsRef={last7SessionsRef}
                last30SessionsRef={last30SessionsRef}
                monthlySessions={monthlySessions}
                isSidebarOpen={props.isSidebarOpen}
                isLoading={isLoading}
                lastActiveIndexRef={lastActiveIndexRef}
                lastSessionRef={lastSessionRef}
                lastActiveSessionRef={lastActiveSessionRef}
                currentIndex={currentIndex}
                loadChatData={(session_id: string) => {
                    loadChatData(session_id);
                }}
                setCurrentIndex={(session_id: string) => {
                    setCurrentIndex(session_id);
                }}
                nextDate={nextDateToLoad}
                loadSessionsData={() => {
                    loadSessionData("");
                }}
            />
            <div
                style={{
                    width: props.isSidebarOpen ? "100%" : "100%",
                    marginLeft: props.isSidebarOpen ? "0vh" : calculateMainDivWidth(),
                    scrollbarWidth: "thin",
                    msOverflowStyle: "-ms-autohiding-scrollbar",
                    alignItems: "center"
                }}
            >
                <Snackbar
                    open={isSnackOpen}
                    autoHideDuration={1000}
                    onClose={handleSnackBarClose}
                    message={i18next.t("template.templateApplied")}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    ContentProps={{
                        style: { backgroundColor: "green", color: "white" }
                    }}
                />
                <Box className={styles.container}>
                    <Box
                        className={styles.chatRoot}
                        style={{
                            height: window.innerHeight - 127,
                            overflowY: "auto",
                            overflowX: "hidden",
                            marginTop: "45px"
                        }}
                    >
                        <div
                            className={styles.commandsContainer}
                            style={{
                                scrollbarWidth: "thin",
                                msOverflowStyle: "-ms-autohiding-scrollbar",
                                alignItems: "center"
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", position: "relative" }}>
                                {windowSize.width < 1100 ? (
                                    <Button
                                        id="ClearChat"
                                        title={i18next.t("clearChat.newChat")}
                                        onClick={clearChat}
                                        disabled={currentIndex === "" || isOnSave}
                                        style={{ marginLeft: "4px" }}
                                    >
                                        <DocumentAddRegular style={{ height: "22px", width: "22px" }} />
                                    </Button>
                                ) : (
                                    <ClearChatButton className={styles.newCommandButton} onClick={clearChat} disabled={currentIndex === "" || isOnSave} />
                                )}

                                {/* <RegenerateButton
                                className={styles.newCommandButton}
                                onClick={regenerate}
                                disabled={currentIndex === "" || isOnSave || applyRestriction}
                            /> */}
                            </div>
                            <div
                                id="AppToggle"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", alignSelf: "center" }}
                            >
                                <span
                                    style={{
                                        padding: "7px",
                                        color: selectedApp === "Manuals" ? "black" : "inherit",
                                        fontWeight: selectedApp === "Manuals" ? "bold" : "normal",
                                        fontSize:
                                            windowSize.width < 1100
                                                ? selectedApp === "Manuals"
                                                    ? "10px"
                                                    : "8px"
                                                : selectedApp === "Manuals"
                                                  ? "large"
                                                  : "inherit",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {i18next.t("chat.manuals")}
                                </span>
                                <Toggle
                                    style={{ top: "6px" }}
                                    label=""
                                    onText=""
                                    offText=""
                                    checked={selectedApp === "FAQ"}
                                    onChange={handleToggleChange}
                                    disabled={(currentView === "chat" && lastQuestionRef.current !== "") || currentView === "history"}
                                />
                                <span
                                    style={{
                                        padding: "7px",
                                        color: selectedApp === "FAQ" ? "black" : "inherit",
                                        fontWeight: selectedApp === "FAQ" ? "bold" : "normal",
                                        fontSize:
                                            windowSize.width < 1100 ? (selectedApp === "FAQ" ? "10px" : "8px") : selectedApp === "FAQ" ? "large" : "inherit"
                                    }}
                                >
                                    {i18next.t("chat.faq")}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    position: "relative",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginRight: "10px"
                                }}
                            >
                                {selectedApp === "Manuals" ? (
                                    <div
                                        style={{
                                            width: windowSize.width < 1100 ? "200px" : "270px",
                                            display: validateProducts("Manuals", false) === false ? "none" : ""
                                        }}
                                        id="ProductManualsFilter"
                                    >
                                        <div
                                            className="Manuals Filter"
                                            style={{
                                                width: windowSize.width < 1100 ? "200px" : "270px",
                                                paddingLeft: "5px",
                                                border: "1px solid black",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display: "flex",
                                                cursor: "pointer",
                                                backgroundColor: "#0078d4",
                                                overflowY: "auto",
                                                minHeight: "30px",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <div
                                                onClick={handleManualsDropdownClick}
                                                style={{
                                                    position: "relative",
                                                    width: windowSize.width < 1100 ? "200px" : "270px",
                                                    height: "auto",
                                                    padding: "2px",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    display: "flex",
                                                    color: "White",
                                                    minHeight: "25px"
                                                }}
                                                title={
                                                    productVersionText === ""
                                                        ? manualsProductText
                                                        : `${manualsProductText} - ${productVersionText} - ${productSubVersionText}`
                                                }
                                            >
                                                <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginRight: "24px" }}>
                                                    {productVersionText === ""
                                                        ? manualsProductText
                                                        : `${manualsProductText} - ${productVersionText} - ${productSubVersionText}`}
                                                </span>
                                                <ChevronDownRegular
                                                    style={{
                                                        position: "absolute",
                                                        right: "2px",
                                                        cursor: "pointer",
                                                        fontSize: "x-large",
                                                        height: "17px",
                                                        top: "3px"
                                                    }}
                                                />
                                            </div>
                                            {isManualsDropdownOpen && (
                                                <div
                                                    ref={manualsDropdownRef}
                                                    style={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: "0",
                                                        display: "block",
                                                        zIndex: "999",
                                                        width: windowSize.width < 1100 ? "200px" : "280px"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: "auto",
                                                            maxHeight: window.innerHeight - 200,
                                                            overflowY: "auto",
                                                            overflowX: "hidden",
                                                            scrollbarWidth: "thin"
                                                        }}
                                                    >
                                                        {products.map((item: any, index) => (
                                                            <div
                                                                className={tabstyles.divOnHover}
                                                                title={item?.text?.toString()}
                                                                key={"index" + index}
                                                                ref={el => (divManualsRefs.current[index] = el)}
                                                                onMouseEnter={() => {
                                                                    setIsHovered(index);
                                                                    setIsSubHovered(-1);
                                                                    setLastHoveredRef(item);
                                                                    setIsSubVersionsHovered(-1);
                                                                    if (item?.versions?.length > 0) {
                                                                        setVersions([{ key: "0", text: "ALL" }, ...item?.versions]);
                                                                        setIsVersionsOpen(true);
                                                                    } else {
                                                                        setVersions([]);
                                                                        setSubVersions([]);
                                                                        setIsVersionsOpen(false);
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    setProductFilter(item?.key.toString() ?? undefined);
                                                                    setManualsProductText(item.text ?? undefined);
                                                                    saveUserSettings(
                                                                        "",
                                                                        item?.key.toString() ?? "",
                                                                        "",
                                                                        "",
                                                                        "",
                                                                        "",
                                                                        "",
                                                                        "",
                                                                        "",
                                                                        "",
                                                                        "0",
                                                                        "0",
                                                                        "",
                                                                        ""
                                                                    );
                                                                    userInfo.product = item?.key.toString() ?? "";
                                                                    setIsManualsDropdownOpen(false);
                                                                    shouldDisableInputBox("Manuals", item?.key, item?.key === "0" ? true : false);
                                                                    if (item?.versions?.length > 0) {
                                                                        setProductVersion("0");
                                                                        setProductVersionText("ALL");
                                                                        setProductSubVersion("0");
                                                                        setProductSubVersionText("ALL");
                                                                    } else {
                                                                        setProductVersion("");
                                                                        setProductVersionText("");
                                                                        setProductSubVersion("");
                                                                        setProductSubVersionText("");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: windowSize.width < 1100 ? "200px" : "270px",
                                                                    padding: windowSize.width < 1100 ? "3px" : "7px",
                                                                    textOverflow: "ellipsis",
                                                                    backgroundColor:
                                                                        isHovered === index
                                                                            ? "#c2c1c1"
                                                                            : productFilter === item?.key.toString()
                                                                              ? "#D4CCC3"
                                                                              : "white",
                                                                    pointerEvents: item?.isSelectable === "false" ? "none" : "auto"
                                                                }}
                                                            >
                                                                {item?.key === productFilter ? (
                                                                    <Text
                                                                        className={styles.ManualsDropDownOptions}
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            opacity: item?.isSelectable === "false" ? "0.5" : "1"
                                                                        }}
                                                                    >
                                                                        {item?.text ?? undefined}
                                                                    </Text>
                                                                ) : (
                                                                    <Text
                                                                        className={styles.ManualsDropDownOptions}
                                                                        style={{ opacity: item?.isSelectable === "false" ? "0.5" : "1", fontSize: "14px" }}
                                                                    >
                                                                        {item?.text ?? undefined}
                                                                    </Text>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {isVersionsOpen && (
                                                        <div
                                                            className={tabstyles.divOnHover}
                                                            style={{
                                                                position: "absolute",
                                                                top: handleTopPosition(
                                                                    divManualsRefs,
                                                                    isHovered !== -1 ? isHovered : 0,
                                                                    lastHoveredRef?.versions?.length
                                                                ),
                                                                right: "100%",
                                                                display: "block",
                                                                height: "auto",
                                                                maxHeight: window.innerHeight - 200,
                                                                width: "280px",
                                                                marginTop: "10px",
                                                                borderRadius: "10px",
                                                                scrollbarWidth: "thin"
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: "auto",
                                                                    maxHeight: window.innerHeight - 200,
                                                                    overflowY: "auto",
                                                                    overflowX: "hidden",
                                                                    scrollbarWidth: "thin",
                                                                    borderRadius: "10px"
                                                                }}
                                                            >
                                                                {versions.map((item: any, index) => (
                                                                    <div
                                                                        key={"index" + index}
                                                                        ref={el => (divVerManualsRefs.current[index] = el)}
                                                                        style={{
                                                                            width: "270px",
                                                                            padding: "7px",
                                                                            textOverflow: "ellipsis",
                                                                            backgroundColor:
                                                                                isSubHovered === index
                                                                                    ? "#c2c1c1"
                                                                                    : productVersion === item?.key.toString()
                                                                                      ? "#D4CCC3"
                                                                                      : "white"
                                                                        }}
                                                                        title={item?.text?.toString()}
                                                                        onMouseEnter={() => {
                                                                            setIsSubHovered(index);
                                                                            setIsSubVersionsHovered(-1);
                                                                            setLastVerHoveredRef(item);
                                                                            if (item?.sub_versions?.length > 0) {
                                                                                setSubVersions([{ key: "0", text: "ALL" }, ...item?.sub_versions]);
                                                                                setIsSubVersionsOpen(true);
                                                                            } else {
                                                                                setSubVersions([]);
                                                                                setIsSubVersionsOpen(false);
                                                                            }
                                                                        }}
                                                                        onClick={() => {
                                                                            if (lastHoveredRef) {
                                                                                setProductFilter(lastHoveredRef?.key.toString() ?? undefined);
                                                                                setManualsProductText(lastHoveredRef.text ?? undefined);
                                                                                saveUserSettings(
                                                                                    "",
                                                                                    lastHoveredRef?.key.toString() ?? "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    item?.key.toString() ?? "",
                                                                                    "0",
                                                                                    "",
                                                                                    ""
                                                                                );
                                                                                userInfo.product = lastHoveredRef?.key.toString() ?? "";
                                                                                shouldDisableInputBox(
                                                                                    "Manuals",
                                                                                    lastHoveredRef?.key,
                                                                                    lastHoveredRef?.key === "0" ? true : false
                                                                                );
                                                                            }
                                                                            setIsManualsDropdownOpen(false);
                                                                            setIsSubVersionsOpen(false);
                                                                            setIsVersionsOpen(false);
                                                                            setProductVersion(item?.key.toString() ?? undefined);
                                                                            setProductVersionText(item.text ?? undefined);
                                                                            setProductSubVersionText("ALL");
                                                                            setProductSubVersion("0");
                                                                            userInfo.productVersion = item?.key.toString() ?? "";
                                                                        }}
                                                                    >
                                                                        {item?.key === productVersion ? (
                                                                            <Text style={{ fontWeight: "bold", fontSize: "medium" }}>
                                                                                {item?.text ?? undefined}
                                                                            </Text>
                                                                        ) : (
                                                                            <Text>{item?.text ?? undefined}</Text>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {isVersionsOpen && isSubVersionsOpen && (
                                                                <div
                                                                    className={tabstyles.divOnHover}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: handleTopPosition(
                                                                            divVerManualsRefs,
                                                                            isSubHovered !== -1 ? isSubHovered : 0,
                                                                            subVersions?.length
                                                                        ),
                                                                        right: "100%",
                                                                        display: "block",
                                                                        height: "auto",
                                                                        maxHeight: window.innerHeight - 200,
                                                                        overflowX: "hidden",
                                                                        width: "280px",
                                                                        marginTop: "10px",
                                                                        borderRadius: "10px",
                                                                        overflowY: "auto",
                                                                        scrollbarWidth: "thin"
                                                                    }}
                                                                >
                                                                    {subVersions.map((item: any, index) => (
                                                                        <div
                                                                            key={"index" + index}
                                                                            style={{
                                                                                width: "270px",
                                                                                padding: "7px",
                                                                                textOverflow: "ellipsis",
                                                                                backgroundColor:
                                                                                    isSubVersionsHovered === index
                                                                                        ? "#c2c1c1"
                                                                                        : productSubVersion === item?.key.toString()
                                                                                          ? "#D4CCC3"
                                                                                          : "white"
                                                                            }}
                                                                            title={item?.text?.toString()}
                                                                            onMouseEnter={() => {
                                                                                setIsSubVersionsHovered(index);
                                                                            }}
                                                                            onClick={() => {
                                                                                if (lastVerHoveredRef) {
                                                                                    setProductFilter(lastHoveredRef?.key.toString() ?? undefined);
                                                                                    setManualsProductText(lastHoveredRef.text ?? undefined);
                                                                                    setProductVersion(lastVerHoveredRef?.key.toString() ?? undefined);
                                                                                    setProductVersionText(lastVerHoveredRef.text ?? undefined);
                                                                                    saveUserSettings(
                                                                                        "",
                                                                                        lastHoveredRef?.key.toString() ?? "",
                                                                                        "",
                                                                                        "",
                                                                                        "",
                                                                                        "",
                                                                                        "",
                                                                                        "",
                                                                                        "",
                                                                                        "",
                                                                                        lastVerHoveredRef?.key.toString() ?? "",
                                                                                        item?.key.toString() ?? "",
                                                                                        "",
                                                                                        ""
                                                                                    );
                                                                                    userInfo.product = lastHoveredRef?.key.toString() ?? "";
                                                                                    userInfo.productVersion = item?.key.toString() ?? "";
                                                                                    shouldDisableInputBox(
                                                                                        "Manuals",
                                                                                        lastHoveredRef?.key,
                                                                                        lastHoveredRef?.key === "0" ? true : false
                                                                                    );
                                                                                }
                                                                                setIsManualsDropdownOpen(false);
                                                                                setIsVersionsOpen(false);
                                                                                setIsSubVersionsOpen(false);
                                                                                setProductSubVersion(item?.key.toString() ?? undefined);
                                                                                setProductSubVersionText(item.text ?? undefined);
                                                                                userInfo.versionIndex = item?.key.toString() ?? "";
                                                                            }}
                                                                        >
                                                                            {item?.key === productSubVersion ? (
                                                                                <Text style={{ fontWeight: "bold", fontSize: "medium" }}>
                                                                                    {item?.text ?? undefined}
                                                                                </Text>
                                                                            ) : (
                                                                                <Text>{item?.text ?? undefined}</Text>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        id="ProductManualsFilter"
                                        className="Product Filter"
                                        style={{
                                            width: windowSize.width < 1100 ? "200px" : "270px",
                                            paddingLeft: "5px",
                                            border: "1px solid black",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: validateProducts("FAQ", false) === false ? "none" : "flex",
                                            cursor: "pointer",
                                            backgroundColor: "#0078d4",
                                            overflowY: "auto",
                                            minHeight: "30px",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div
                                            onClick={handleMainDropdownClick}
                                            style={{
                                                position: "relative",
                                                width: windowSize.width < 1100 ? "200px" : "270px",
                                                height: "auto",
                                                padding: "2px",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                display: "flex",
                                                color: "White",
                                                minHeight: "25px"
                                            }}
                                            title={
                                                versionFAQProductText === ""
                                                    ? subProductText !== ""
                                                        ? `${productText} - ${subProductText}`
                                                        : productText
                                                    : `${productText} - ${versionFAQProductText} - ${subProductText}`
                                            }
                                        >
                                            <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginRight: "24px" }}>
                                                {versionFAQProductText === ""
                                                    ? subProductText !== ""
                                                        ? `${productText} - ${subProductText}`
                                                        : productText
                                                    : `${productText} - ${versionFAQProductText} - ${subProductText}`}
                                            </span>

                                            <ChevronDownRegular
                                                style={{
                                                    position: "absolute",
                                                    right: "2px",
                                                    cursor: "pointer",
                                                    fontSize: "x-large",
                                                    height: "17px",
                                                    top: "3px"
                                                }}
                                            />
                                        </div>
                                        {isMainDropdownOpen && (
                                            <div
                                                ref={mainDropdownRef}
                                                style={{
                                                    position: "absolute",
                                                    top: "100%",
                                                    left: "0",
                                                    display: "block",
                                                    zIndex: "999",
                                                    width: windowSize.width < 1100 ? "200px" : "280px"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "auto",
                                                        maxHeight: window.innerHeight - 200,
                                                        overflowY: "auto",
                                                        overflowX: "hidden",
                                                        scrollbarWidth: "thin"
                                                    }}
                                                >
                                                    {products.map((item: any, index) => (
                                                        <div
                                                            className={tabstyles.divOnHover}
                                                            title={item?.text?.toString()}
                                                            key={"index" + index}
                                                            ref={el => (divFAQRefs.current[index] = el)}
                                                            onMouseEnter={() => {
                                                                setIsHovered(index);
                                                                setIsSubHovered(-1);
                                                                setIsSubFAQVersionsHovered(-1);
                                                                setLastHoveredRef(item);
                                                                setLastVerFAQHoveredRef(undefined);
                                                                if (item?.versions?.length > 0) {
                                                                    setSubFAQVersions([{ key: "0", text: "ALL" }, ...item?.versions]);
                                                                    setIsFAQVersionsOpen(true);
                                                                    setSubProducts([]);
                                                                    setIsSubDropdownOpen(false);
                                                                } else if (item?.data?.length > 0) {
                                                                    setSubFAQVersions([]);
                                                                    setSubProducts([{ key: "0", text: "ALL" }, ...item?.data]);
                                                                    setIsSubDropdownOpen(true);
                                                                    setIsFAQVersionsOpen(false);
                                                                } else {
                                                                    setSubProducts([]);
                                                                    setSubFAQVersions([]);
                                                                    setIsSubDropdownOpen(false);
                                                                    setIsFAQVersionsOpen(false);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                setFaqProductFilter(item?.key.toString() ?? undefined);
                                                                setProductText(item.text ?? undefined);
                                                                setSubProductFilter("0");
                                                                setSubProductText("ALL");
                                                                saveUserSettings(
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    item?.key.toString() ?? "",
                                                                    "0",
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    "",
                                                                    ""
                                                                );
                                                                if (!item?.versions) {
                                                                    setVersionFAQProduct("");
                                                                    setVersionFAQProductText("");
                                                                }
                                                                userInfo.faqProduct = item?.key.toString() ?? "";
                                                                userInfo.subProduct = "0";
                                                                setIsMainDropdownOpen(false);
                                                                shouldDisableInputBox("FAQ", item?.key, item?.key === "0" ? true : false);
                                                            }}
                                                            style={{
                                                                width: windowSize.width < 1100 ? "200px" : "270px",
                                                                padding: windowSize.width < 1100 ? "3px" : "7px",
                                                                textOverflow: "ellipsis",
                                                                backgroundColor:
                                                                    isHovered === index
                                                                        ? "#c2c1c1"
                                                                        : faqProductFilter === item?.key.toString()
                                                                          ? "#D4CCC3"
                                                                          : "white"
                                                            }}
                                                        >
                                                            {item?.key === faqProductFilter ? (
                                                                <Text
                                                                    className={styles.FaqDropDownOptions}
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        opacity: item?.isSelectable === "false" ? "0.5" : "1"
                                                                    }}
                                                                >
                                                                    {item?.text ?? undefined}
                                                                </Text>
                                                            ) : (
                                                                <Text
                                                                    className={styles.FaqDropDownOptions}
                                                                    style={{ opacity: item?.isSelectable === "false" ? "0.5" : "1" }}
                                                                >
                                                                    {item?.text ?? undefined}
                                                                </Text>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                                {isFAQVersionsOpen && (
                                                    <div
                                                        className={tabstyles.divOnHover}
                                                        style={{
                                                            position: "absolute",
                                                            top: handleTopPosition(
                                                                divFAQRefs,
                                                                isHovered !== -1 ? isHovered : 0,
                                                                lastHoveredRef?.versions?.length
                                                            ),
                                                            right: "100%",
                                                            display: "block",
                                                            height: "auto",
                                                            maxHeight: window.innerHeight - 200,
                                                            width: "280px",
                                                            marginTop: "10px",
                                                            borderRadius: "10px",
                                                            scrollbarWidth: "thin"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: "auto",
                                                                maxHeight: window.innerHeight - 200,
                                                                overflowY: "auto",
                                                                overflowX: "hidden",
                                                                scrollbarWidth: "thin",
                                                                borderRadius: "10px"
                                                            }}
                                                        >
                                                            {subFAQVersions.map((item: any, index) => (
                                                                <div
                                                                    key={"index" + index}
                                                                    ref={el => (divVerFAQRefs.current[index] = el)}
                                                                    style={{
                                                                        width: "270px",
                                                                        padding: "7px",
                                                                        textOverflow: "ellipsis",
                                                                        backgroundColor:
                                                                            isSubFAQVersionsHovered === index
                                                                                ? "#c2c1c1"
                                                                                : versionFAQProduct === item?.key.toString()
                                                                                  ? "#D4CCC3"
                                                                                  : "white"
                                                                    }}
                                                                    title={item?.text?.toString()}
                                                                    onMouseEnter={() => {
                                                                        setIsSubHovered(-1);
                                                                        setIsSubFAQVersionsHovered(index);
                                                                        setLastVerFAQHoveredRef(item);
                                                                        if (item?.sub_versions?.length > 0) {
                                                                            setSubProducts([{ key: "0", text: "ALL" }, ...item?.sub_versions]);
                                                                            setIsSubDropdownOpen(true);
                                                                        } else {
                                                                            setSubProducts([]);
                                                                            setIsSubDropdownOpen(false);
                                                                        }
                                                                    }}
                                                                    onClick={() => {
                                                                        if (lastHoveredRef) {
                                                                            setFaqProductFilter(lastHoveredRef?.key.toString() ?? undefined);
                                                                            setProductText(lastHoveredRef.text ?? undefined);
                                                                            saveUserSettings(
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                lastHoveredRef?.key.toString() ?? "",
                                                                                "0",
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                "",
                                                                                item?.key.toString() ?? ""
                                                                            );
                                                                            userInfo.faqProduct = lastHoveredRef?.key.toString() ?? "";
                                                                            shouldDisableInputBox(
                                                                                "FAQ",
                                                                                lastHoveredRef?.key,
                                                                                lastHoveredRef?.key === "0" ? true : false
                                                                            );
                                                                        }
                                                                        setIsMainDropdownOpen(false);
                                                                        setIsFAQVersionsOpen(false);
                                                                        setVersionFAQProduct(item?.key.toString() ?? undefined);
                                                                        setVersionFAQProductText(item.text ?? undefined);
                                                                        setSubProductText("ALL");
                                                                        setSubProductFilter("0");
                                                                        userInfo.subFAQVersion = item?.key.toString() ?? "";
                                                                        userInfo.subProduct = "0";
                                                                    }}
                                                                >
                                                                    {item?.key === versionFAQProduct ? (
                                                                        <Text style={{ fontWeight: "bold", fontSize: "medium" }}>
                                                                            {item?.text ?? undefined}
                                                                        </Text>
                                                                    ) : (
                                                                        <Text>{item?.text ?? undefined}</Text>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {isFAQVersionsOpen && isSubDropdownOpen && (
                                                            <div
                                                                className={tabstyles.divOnHover}
                                                                style={{
                                                                    position: "absolute",
                                                                    top: handleTopPosition(
                                                                        divVerFAQRefs,
                                                                        isSubFAQVersionsHovered !== -1 ? isSubFAQVersionsHovered : 0,
                                                                        subProducts.length
                                                                    ),
                                                                    right: "100%",
                                                                    display: "block",
                                                                    height: "auto",
                                                                    maxHeight: window.innerHeight - 200,
                                                                    overflowX: "hidden",
                                                                    width: windowSize.width < 1100 ? "170px" : "280px",
                                                                    marginTop: "10px",
                                                                    borderRadius: "10px",
                                                                    overflowY: "auto",
                                                                    scrollbarWidth: "thin"
                                                                }}
                                                            >
                                                                {subProducts.map((item, index) => (
                                                                    <div
                                                                        key={"index" + index}
                                                                        style={{
                                                                            width: windowSize.width < 1100 ? "170px" : "270px",
                                                                            padding: windowSize.width < 1100 ? "3px" : "7px",
                                                                            textOverflow: "ellipsis",
                                                                            backgroundColor:
                                                                                isSubHovered === index
                                                                                    ? "#c2c1c1"
                                                                                    : subProductFilter === item?.key.toString()
                                                                                      ? "#D4CCC3"
                                                                                      : "white"
                                                                        }}
                                                                        title={item?.text?.toString()}
                                                                        onMouseEnter={() => {
                                                                            setIsSubHovered(index);
                                                                        }}
                                                                        onClick={() => {
                                                                            if (lastHoveredRef) {
                                                                                setFaqProductFilter(lastHoveredRef?.key.toString() ?? undefined);
                                                                                setProductText(lastHoveredRef.text ?? undefined);
                                                                                if (lastVerFAQHoveredRef) {
                                                                                    setVersionFAQProduct(lastVerFAQHoveredRef?.key.toString() ?? undefined);
                                                                                    setVersionFAQProductText(lastVerFAQHoveredRef.text ?? undefined);
                                                                                } else {
                                                                                    setVersionFAQProduct("");
                                                                                    setVersionFAQProductText("");
                                                                                }
                                                                                saveUserSettings(
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    lastHoveredRef?.key.toString() ?? "",
                                                                                    item?.key.toString() ?? "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    "",
                                                                                    lastVerFAQHoveredRef?.key.toString() ?? ""
                                                                                );
                                                                                userInfo.faqProduct = lastHoveredRef?.key.toString() ?? "";
                                                                                userInfo.subFAQVersion = lastVerFAQHoveredRef?.key.toString() ?? "";
                                                                                shouldDisableInputBox(
                                                                                    "FAQ",
                                                                                    lastHoveredRef?.key,
                                                                                    lastHoveredRef?.key === "0" ? true : false
                                                                                );
                                                                            }
                                                                            setIsMainDropdownOpen(false);
                                                                            setIsSubDropdownOpen(false);
                                                                            setSubProductFilter(item?.key.toString() ?? undefined);
                                                                            setSubProductText(item.text ?? undefined);
                                                                            userInfo.subProduct = item?.key.toString() ?? "";
                                                                        }}
                                                                    >
                                                                        {item?.key === subProductFilter ? (
                                                                            <Text className={styles.FaqDropDownOptions} style={{ fontWeight: "bold" }}>
                                                                                {item?.text ?? undefined}
                                                                            </Text>
                                                                        ) : (
                                                                            <Text className={styles.FaqDropDownOptions}>{item?.text ?? undefined}</Text>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {!isFAQVersionsOpen && isSubDropdownOpen && (
                                                    <div
                                                        className={tabstyles.divOnHover}
                                                        style={{
                                                            position: "absolute",
                                                            top: handleTopPosition(
                                                                lastVerFAQHoveredRef ? divVerFAQRefs : divFAQRefs,
                                                                lastVerFAQHoveredRef
                                                                    ? isSubFAQVersionsHovered !== -1
                                                                        ? isSubFAQVersionsHovered
                                                                        : 0
                                                                    : isHovered !== -1
                                                                      ? isHovered
                                                                      : 0,
                                                                subProducts.length
                                                            ),
                                                            right: "100%",
                                                            display: "block",
                                                            height: "auto",
                                                            maxHeight: window.innerHeight - 200,
                                                            overflowX: "hidden",
                                                            width: windowSize.width < 1100 ? "170px" : "280px",
                                                            marginTop: "10px",
                                                            borderRadius: "10px",
                                                            overflowY: "auto",
                                                            scrollbarWidth: "thin"
                                                        }}
                                                    >
                                                        {subProducts.map((item, index) => (
                                                            <div
                                                                key={"index" + index}
                                                                style={{
                                                                    width: windowSize.width < 1100 ? "170px" : "270px",
                                                                    padding: windowSize.width < 1100 ? "3px" : "7px",
                                                                    textOverflow: "ellipsis",
                                                                    backgroundColor:
                                                                        isSubHovered === index
                                                                            ? "#c2c1c1"
                                                                            : subProductFilter === item?.key.toString()
                                                                              ? "#D4CCC3"
                                                                              : "white"
                                                                }}
                                                                title={item?.text?.toString()}
                                                                onMouseEnter={() => {
                                                                    setIsSubHovered(index);
                                                                }}
                                                                onClick={() => {
                                                                    if (lastHoveredRef) {
                                                                        setFaqProductFilter(lastHoveredRef?.key.toString() ?? undefined);
                                                                        setProductText(lastHoveredRef.text ?? undefined);
                                                                        setVersionFAQProduct("");
                                                                        setVersionFAQProductText("");
                                                                        saveUserSettings(
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            lastHoveredRef?.key.toString() ?? "",
                                                                            item?.key.toString() ?? "",
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            "",
                                                                            ""
                                                                        );
                                                                        userInfo.subFAQVersion = "";
                                                                        userInfo.faqProduct = lastHoveredRef?.key.toString() ?? "";
                                                                        shouldDisableInputBox(
                                                                            "FAQ",
                                                                            lastHoveredRef?.key,
                                                                            lastHoveredRef?.key === "0" ? true : false
                                                                        );
                                                                    }
                                                                    setIsMainDropdownOpen(false);
                                                                    setIsSubDropdownOpen(false);
                                                                    setSubProductFilter(item?.key.toString() ?? undefined);
                                                                    setSubProductText(item.text ?? undefined);
                                                                    userInfo.subProduct = item?.key.toString() ?? "";
                                                                }}
                                                            >
                                                                {item?.key === subProductFilter ? (
                                                                    <Text className={styles.FaqDropDownOptions} style={{ fontWeight: "bold" }}>
                                                                        {item?.text ?? undefined}
                                                                    </Text>
                                                                ) : (
                                                                    <Text className={styles.FaqDropDownOptions}>{item?.text ?? undefined}</Text>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {isWapUser && (
                                    <div className="Document Categories" style={{ width: "100px", paddingLeft: "5px" }}>
                                        <Dropdown
                                            id="doc_type_filter"
                                            title={i18next.t("chat.documenttype")}
                                            selectedKey={docTypeFilter}
                                            placeholder={i18next.t("chat.documenttype")}
                                            options={categories}
                                            onChange={(event, item) => {
                                                setDocTypeFilter(item?.key?.toString() ?? undefined);
                                                saveUserSettings(item?.key?.toString() ?? "", "", "", "", "", "", "", "", "", "", "", "", "", "");
                                                userInfo.docValue = item?.key?.toString() ?? "";
                                            }}
                                            onRenderOption={item => {
                                                return (
                                                    <Text
                                                        className={styles.documentCategories}
                                                        title={i18next.t(`${item?.text ?? undefined}`)}
                                                        style={{ padding: "8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                    >
                                                        {i18next.t(`${item?.text ?? undefined}`)}
                                                    </Text>
                                                );
                                            }}
                                            style={{ fontSize: windowSize.width < 1100 ? "10px" : "15px" }}
                                        />
                                    </div>
                                )}
                                {isAdmin && (
                                    <div className="AI model" style={{ width: "155px", paddingLeft: "5px" }}>
                                        <Dropdown
                                            title={i18next.t("chat.chatModel")}
                                            className={styles.aimodeloptions}
                                            selectedKey={chatModel}
                                            options={aiModel_admin}
                                            onChange={onChatModelChange}
                                        />
                                    </div>
                                )}
                                {/* {!isAdmin && (
                                <div className="AI model" style={{ width: "160px", paddingLeft: "5px", marginRight: "20px" }}>
                                    <Dropdown
                                        title={i18next.t("chat.chatModel")}
                                        className={styles.aimodeloptions}
                                        selectedKey={chatModel}
                                        options={aimodel_user}
                                        onChange={onChatModelChange}
                                    />
                                </div>
                            )} */}
                                {isAdmin && (
                                    <div
                                        title={i18next.t("chat.developerSettings")}
                                        style={{ display: "flex", justifyContent: "center", alignContent: "center", marginLeft: "3px" }}
                                    >
                                        <Settings32Regular className={styles.commandSettingsButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div id="chatAndCitationContainer" className={styles.chatAndCitationContainer}>
                            <Box className={styles.chatContainer}>
                                {!lastQuestionRef.current && currentView !== "history" ? (
                                    <div className={styles.chatEmptyState}>
                                        <div className={styles.disclaimerContainer} style={{ height: "60%" }}>
                                            <p style={{ margin: "0", display: "flex" }}>
                                                <img
                                                    src={DisclaimerIcon}
                                                    alt="Icon"
                                                    style={{ width: "20px", height: "20px", marginRight: "10px", paddingTop: "5px" }}
                                                />
                                                <div>
                                                    {i18next.t("chat.disclaimerHeader1")}
                                                    <Link id="TermsConditions" to="/assistant/terms" target="_blank" style={{ color: "#0004fa" }}>
                                                        {i18next.t("chat.disclaimerHeader2")}
                                                    </Link>
                                                    {i18next.t("chat.disclaimerHeader3")}
                                                    <br />
                                                    {i18next.t("chat.guideHeader1")}
                                                    <Link id="UserGuidlines" to="/assistant/guide" target="_blank" style={{ color: "#0004fa" }}>
                                                        {i18next.t("chat.guideHeader2")}
                                                    </Link>
                                                    {i18next.t("chat.guideHeader3")}
                                                </div>
                                            </p>
                                            <div
                                                className={styles.disclaimerInnerContainer}
                                                style={{ overflowY: "auto", scrollbarWidth: "thin", msOverflowStyle: "-ms-autohiding-scrollbar" }}
                                            >
                                                <p className={styles.disclaimerInnerTitle}>
                                                    <PersonInfoFilled /> {i18next.t("chat.disclaimerHeading")}
                                                </p>
                                                <ol style={{ fontSize: userInfo.locale === "ja" || userInfo.locale === "zh" ? "13px" : "14px" }}>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList1Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList1")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList2Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList2")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList3Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList3")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList4Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList4")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList5Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList5")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList6Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList6")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList7Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList7")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList8Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList8")}</p>
                                                    </li>
                                                    <li>
                                                        <p style={{ display: "inline", fontWeight: "bold" }}>{i18next.t("chat.disclaimerList9Header")}</p>:
                                                        <p style={{ display: "inline", marginLeft: "5px" }}>{i18next.t("chat.disclaimerList9")}</p>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>

                                        {/* <div className={styles.chatExampleContainer}>
                                <h4 className={styles.chatEmptyStateSubtitle}>{i18next.t("chat.subtitle")}:</h4>
                                <ExampleList onExampleClicked={onExampleClicked} />
                              </div> */}

                                        <div className={styles.chatInput}>
                                            {showSupportLink && (
                                                <h5 style={{ margin: "0px", paddingBottom: "5px", fontSize: "11px" }}>
                                                    {i18next.t("chat.link_info")}
                                                    <a style={{ color: "blue" }} href={supportEnvBoard} target="_blank">
                                                        {i18next.t("chat.link_text")}
                                                    </a>
                                                </h5>
                                            )}
                                            <QuestionInput
                                                clearOnSend
                                                placeholder={applyRestriction ? i18next.t("chat.no_input") : i18next.t("chat.input")}
                                                disabled={isLoading || isAnswerLoading || applyRestriction}
                                                isAdmin={isAdmin}
                                                productIndex={selectedApp === "Manuals" ? productFilter : faqProductFilter}
                                                onSend={question => {
                                                    if (question !== "invalid_selection") {
                                                        makeApiRequest(question);
                                                        setEmptyProduct(false);
                                                        setSelectedTemplateContent("");
                                                        setHideDynamicTemplatePopup(hideDynamicTemplatePopup);
                                                    } else {
                                                        setEmptyProduct(true);
                                                    }
                                                }}
                                                onTemplateIconClick={handleTemplateIconClick}
                                                templateContent={selectedTemplateContent}
                                                onClearTemplateContent={handleClearTemplateContent}
                                                disableTextarea={applyRestriction}
                                                isEdited={isEdited}
                                                setIsEdited={setIsEdited}
                                                clearChatTriggered={clearChatTriggered}
                                                setClearChatTriggered={setClearChatTriggered}
                                                chatContentCleared={chatContentCleared}
                                                setChatContentCleared={setChatContentCleared}
                                            />
                                            {/* <p className={styles.disclaimerText} style={{ marginTop: "10px" }}>
                                        {i18next.t("chat.disclaimer")}
                                    </p> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className={styles.chatMessageInputDiv}
                                        // style={{
                                        //     height: window.innerHeight - 280
                                        // }}
                                    >
                                        <div ref={answerAreaRef} className={styles.chatMessageStream}>
                                            {isStreaming &&
                                                streamedAnswers.map((streamedAnswer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage
                                                            key={index}
                                                            message={streamedAnswer[0]}
                                                            onEditClick={value => onEditClick(value, index)}
                                                            selectedQuestion={selectedQuestion === index}
                                                            applyRestriction={applyRestriction}
                                                            reference={chatResponseAreaRef}
                                                        />
                                                        <div className={styles.chatMessageGpt} ref={chatResponseAreaRef}>
                                                            {(streamedAnswer[1]?.choices[0].context.keywords !== undefined &&
                                                                streamedAnswer[1]?.choices[0].context.feedbackgiven === false) ||
                                                            streamedAnswer[1]?.choices[0].context.feedback?.category === 0 ? (
                                                                <Answer
                                                                    isStreaming={true}
                                                                    key={index}
                                                                    doctype={doctype}
                                                                    updateStreamedAnswer={updateStreamedAnswer}
                                                                    answer={streamedAnswer[1]}
                                                                    isSelected={false}
                                                                    onCitationClicked={(c, citationIndex) => onShowCitation(c, citationIndex, index)}
                                                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                    onSupportingContentClicked={() =>
                                                                        onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                                                                    }
                                                                    onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                    showFollowupQuestions={useSuggestFollowupQuestions && streamedAnswers.length - 1 === index}
                                                                    onFeedbackSubmitClicked={feedback => onFeedbackSubmitClicked(feedback)}
                                                                    primaryId={index.toString()}
                                                                    onClickLikeDislike={() => {
                                                                        lastAnswerIndexRef.current = index;
                                                                    }}
                                                                    isAdminUser={isAdmin}
                                                                    displayLink={() => {
                                                                        setShowSupportLink(true);
                                                                    }}
                                                                    isLastAnswer={
                                                                        streamedAnswers?.length === 0 ||
                                                                        (streamedAnswers?.length > 0 && streamedAnswers?.length - 1 === index)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    isFeedbackPage={false}
                                                                />
                                                            ) : (
                                                                <AnswersHistory
                                                                    key={index}
                                                                    doctype={doctype}
                                                                    answer={streamedAnswer[1]}
                                                                    isStreaming={false}
                                                                    isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                    onCitationClicked={(c, citationIndex) => onShowCitation(c, citationIndex, index)}
                                                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                    onSupportingContentClicked={() =>
                                                                        onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                                                                    }
                                                                    showFollowupQuestions={useSuggestFollowupQuestions && streamedAnswers.length - 1 === index}
                                                                    isHistory={true}
                                                                    isAdminUser={isAdmin}
                                                                    displayLink={() => {
                                                                        setShowSupportLink(true);
                                                                    }}
                                                                    isLastAnswer={
                                                                        streamedAnswers?.length === 0 ||
                                                                        (streamedAnswers?.length > 0 && streamedAnswers?.length - 1 === index)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    isFeedbackPage={false}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            {!isStreaming &&
                                                Array.isArray(answers) &&
                                                answers.map((answer, index) => (
                                                    <div key={index}>
                                                        <UserChatMessage
                                                            key={index}
                                                            message={answer[0]}
                                                            onSend={question => makeApiRequest(question, undefined, undefined, undefined, index, IsOnEdit)}
                                                            conversation={conversationDetails}
                                                            index={index}
                                                            answers={answers}
                                                            streamedAnswers={streamedAnswers}
                                                            primaryReference={primaryIDMap}
                                                            onEditClick={value => onEditClick(value, index)}
                                                            IsOnEdit={IsOnEdit}
                                                            isChatShared={isChatShared}
                                                            isAdminUser={false}
                                                            selectedQuestion={selectedQuestion === index}
                                                            applyRestriction={applyRestriction}
                                                            reference={chatResponseAreaRef}
                                                        />
                                                        <div className={styles.chatMessageGpt} ref={chatResponseAreaRef}>
                                                            {(answer[1]?.choices[0].context.keywords !== undefined &&
                                                                answer[1]?.choices[0].context.feedbackgiven === false) ||
                                                            answer[1]?.choices[0].context.feedback?.category === 0 ? (
                                                                <Answer
                                                                    isStreaming={false}
                                                                    key={index}
                                                                    doctype={doctype}
                                                                    updateStreamedAnswer={updateStreamedAnswer}
                                                                    answer={answer[1]}
                                                                    isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                    onCitationClicked={(c, citationIndex) => onShowCitation(c, citationIndex, index)}
                                                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                    onSupportingContentClicked={() =>
                                                                        onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                                                                    }
                                                                    onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                                    showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                    onFeedbackSubmitClicked={feedback => onFeedbackSubmitClicked(feedback)}
                                                                    primaryId={index.toString()}
                                                                    onClickLikeDislike={() => {
                                                                        lastAnswerIndexRef.current = index;
                                                                    }}
                                                                    isAdminUser={isAdmin}
                                                                    displayLink={() => {
                                                                        setShowSupportLink(true);
                                                                    }}
                                                                    isLastAnswer={
                                                                        answers?.length === 0 || (answers?.length > 0 && answers?.length - 1 === index)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    isFeedbackPage={false}
                                                                />
                                                            ) : (
                                                                <AnswersHistory
                                                                    key={index}
                                                                    doctype={doctype}
                                                                    answer={answer[1]}
                                                                    isStreaming={false}
                                                                    isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                    onCitationClicked={(c, citationIndex) => onShowCitation(c, citationIndex, index)}
                                                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                    onSupportingContentClicked={() =>
                                                                        onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                                                                    }
                                                                    showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                    isHistory={true}
                                                                    isAdminUser={isAdmin}
                                                                    displayLink={() => {
                                                                        setShowSupportLink(true);
                                                                    }}
                                                                    isLastAnswer={
                                                                        answers?.length === 0 || (answers?.length > 0 && answers?.length - 1 === index)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    isFeedbackPage={false}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}

                                            {isLoading && (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} onEditClick={value => onEditClick(value, 0)} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </>
                                            )}
                                            {error ? (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} onEditClick={value => onEditClick(value, 0)} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                    </div>
                                                </>
                                            ) : null}
                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    </div>
                                )}
                                <DynamicTemplatePrompt
                                    hideDynamicTemplatePopup={hideDynamicTemplatePopup}
                                    handleTemplateIconClick={handleTemplateIconClick}
                                    selectedTemplate={selectedTemplate}
                                    handleTemplateClick={handleTemplateClick}
                                    handleTemplateContents={handleTemplateContents}
                                    products={products}
                                    selectedProduct={selectedApp === "Manuals" ? manualsProductText : productText}
                                    snackbar={handleSnackBar}
                                    setIsEdited={setIsEdited}
                                />
                                {(newChatQuestion !== "" || currentView === "history") && (
                                    <div className={styles.chatInput2}>
                                        {showSupportLink && (
                                            <h5 style={{ margin: "0px", paddingBottom: "5px", fontSize: "11px" }}>
                                                {i18next.t("chat.link_info")}
                                                <a style={{ color: "blue" }} href={supportEnvBoard} target="_blank">
                                                    {i18next.t("chat.link_text")}
                                                </a>
                                            </h5>
                                        )}
                                        <QuestionInput
                                            clearOnSend
                                            placeholder={applyRestriction ? i18next.t("chat.no_input") : i18next.t("chat.inputPlaceholder")}
                                            disabled={isLoading || isAnswerLoading || applyRestriction}
                                            onSend={question => {
                                                if (question !== "invalid_selection") {
                                                    setCurrentIndex(sessionStorage.getItem("sessionId") ?? "");
                                                    makeApiRequest(question);
                                                    setEmptyProduct(false);
                                                    setHideDynamicTemplatePopup(hideDynamicTemplatePopup);
                                                    setSelectedTemplateContent("");
                                                } else {
                                                    setEmptyProduct(true);
                                                }
                                            }}
                                            onTemplateIconClick={handleTemplateIconClick}
                                            templateContent={selectedTemplateContent}
                                            onClearTemplateContent={handleClearTemplateContent}
                                            isAdmin={isAdmin}
                                            productIndex={selectedApp === "Manuals" ? productFilter : faqProductFilter}
                                            disableTextarea={applyRestriction}
                                            isEdited={isEdited}
                                            setIsEdited={setIsEdited}
                                            clearChatTriggered={clearChatTriggered}
                                            setClearChatTriggered={setClearChatTriggered}
                                            chatContentCleared={chatContentCleared}
                                            setChatContentCleared={setChatContentCleared}
                                        />
                                        {/* <p className={styles.disclaimerText} style={{ marginTop: "10px" }}>
                                    {" "}
                                    {i18next.t("chat.disclaimer")}{" "}
                                </p> */}
                                    </div>
                                )}
                            </Box>
                            {answers.length > 0 && activeAnalysisPanelTab && answers[selectedAnswer] && (
                                <AnalysisPanel
                                    className={styles.chatAnalysisPanel}
                                    activeCitation={activeCitation}
                                    onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                    citationHeight="810px"
                                    answer={answers[selectedAnswer][1]}
                                    activeTab={activeAnalysisPanelTab}
                                    index={selectedCitation}
                                    isAdminUser={isAdmin}
                                    onActiveContentChanged={citationIndex => {
                                        setSelectedCitation(citationIndex);
                                    }}
                                    isStreaming={isStreaming}
                                    sourcePage={"Chat"}
                                />
                            )}
                        </div>
                        <Panel
                            headerText="Configure answer generation"
                            isOpen={isConfigPanelOpen}
                            isBlocking={false}
                            onDismiss={() => setIsConfigPanelOpen(false)}
                            closeButtonAriaLabel="Close"
                            onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                            isFooterAtBottom={true}
                        >
                            <TextField
                                className={styles.chatSettingsSeparator}
                                defaultValue={promptTemplate}
                                label="Override prompt template"
                                multiline
                                autoAdjustHeight
                                onChange={onPromptTemplateChange}
                            />

                            <SpinButton
                                className={styles.chatSettingsSeparator}
                                label="Retrieve this many search results:"
                                min={1}
                                max={50}
                                defaultValue={retrieveCount.toString()}
                                onChange={onRetrieveCountChange}
                            />
                            <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                            <Checkbox
                                className={styles.chatSettingsSeparator}
                                checked={useSemanticRanker}
                                label="Use semantic ranker for retrieval"
                                onChange={onUseSemanticRankerChange}
                            />
                            <Checkbox
                                className={styles.chatSettingsSeparator}
                                checked={useSemanticCaptions}
                                label="Use query-contextual summaries instead of whole documents"
                                onChange={onUseSemanticCaptionsChange}
                                disabled={!useSemanticRanker}
                            />
                            <Checkbox
                                className={styles.chatSettingsSeparator}
                                checked={useSuggestFollowupQuestions}
                                label="Suggest follow-up questions"
                                onChange={onUseSuggestFollowupQuestionsChange}
                                disabled={onlyCitations}
                            />
                            {hidetoken && (
                                <Checkbox
                                    className={styles.chatSettingsSeparator}
                                    checked={useOidSecurityFilter}
                                    label="Use oid security filter"
                                    disabled={true}
                                    onChange={onUseOidSecurityFilterChange}
                                />
                            )}
                            {hidetoken && (
                                <Checkbox
                                    className={styles.chatSettingsSeparator}
                                    checked={useGroupsSecurityFilter}
                                    label="Use groups security filter"
                                    disabled={true}
                                    onChange={onUseGroupsSecurityFilterChange}
                                />
                            )}
                            {/* <Dropdown
                                className={styles.chatSettingsSeparator}
                                label="Azure OpenAI Model"
                                options={[
                                    { key: "gpt-4o", text: "gpt-4o", selected: chatModel == ChatModel.GPT_4O, data: ChatModel.GPT_4O },
                                    { key: "gpt-4o-mini", text: "gpt-4o-mini", selected: chatModel == ChatModel.GPT_4O_MINI, data: ChatModel.GPT_4O_MINI }
                                ]}
                                required
                                onChange={onChatModelChange}
                                selectedKey={chatModel}
                            /> */}

                            <Dropdown
                                className={styles.chatSettingsSeparator}
                                label="Retrieval mode"
                                options={retrievalOptions}
                                required
                                onChange={onRetrievalModeChange}
                                selectedKey={retrievalMode}
                            />
                            <Checkbox
                                className={styles.chatSettingsSeparator}
                                checked={shouldStream}
                                label="Stream chat completion responses"
                                onChange={onShouldStreamChange}
                                disabled={onlyCitations}
                            />
                            <Checkbox
                                className={styles.chatSettingsSeparator}
                                checked={onlyCitations}
                                label="Show only citations"
                                onChange={onOnlyCitationsChange}
                            />

                            {hidetoken && <TokenClaimsDisplay />}
                        </Panel>
                    </Box>
                    {localStorage.getItem("private_warning") === "true" && localStorage.getItem("show_warning") === "true" && (
                        <Layer>
                            <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={showWarning}>
                                <Overlay />
                                <FocusTrapZone>
                                    <div role="document" className={popupStyles.content}>
                                        <div>
                                            <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                            <IconButton style={{ color: "green", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={showWarning} />
                                            <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                                <Trans i18nKey="chat.warning1" />
                                            </span>
                                            <span style={{ padding: "5px", fontSize: "15px" }}>
                                                <Trans i18nKey="chat.warning2" />
                                            </span>
                                            <span style={{ padding: "5px", fontSize: "15px" }}>
                                                <Trans i18nKey="chat.warning3" />
                                            </span>
                                            <span style={{ padding: "5px", fontSize: "15px" }}>
                                                <Trans i18nKey="chat.warning4" />
                                            </span>
                                        </div>
                                        <DefaultButton
                                            autoFocus
                                            style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px", top: "10px" }}
                                            onClick={showWarning}
                                        >
                                            {i18next.t("chat.ok")}
                                        </DefaultButton>
                                    </div>
                                </FocusTrapZone>
                            </Popup>
                        </Layer>
                    )}
                    {emptyProduct && (
                        <Layer>
                            <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={clearWarning}>
                                <Overlay />
                                <FocusTrapZone>
                                    <div role="document" className={popupStyles.content}>
                                        <div>
                                            <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                            <IconButton style={{ color: "green", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={clearWarning} />
                                            <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                                <Trans i18nKey="chat.warning6" />
                                            </span>
                                            <span style={{ padding: "5px", fontSize: "15px" }}>{<Trans i18nKey="chat.warning7" />}</span>
                                        </div>
                                        <DefaultButton
                                            autoFocus
                                            style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px", top: "10px" }}
                                            onClick={clearWarning}
                                        >
                                            {i18next.t("chat.ok")}
                                        </DefaultButton>
                                    </div>
                                </FocusTrapZone>
                            </Popup>
                        </Layer>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default Chat;
